import { ReactNode, useEffect } from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};
`

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: ${({ theme }) => theme.colors.overlay[500]};
  cursor: ${({ onClick }) => (!!onClick ? 'pointer' : 'default')};
`

type ContentWrapperProps = {
  size: keyof typeof ModalSize
}

const ContentWrapperSizes = {
  small: 480,
  default: 1056,
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.white[500]};
  z-index: 1;
  min-height: 100%;
  width: 100%;
  max-width: ${({ size }) => ContentWrapperSizes[size]}px;
  overflow-x: hidden;
  overflow-y: scroll;
`
enum ModalSize {
  small = 'small',
  default = 'default',
}

export type ModalProps = {
  children: ReactNode
  size?: keyof typeof ModalSize
  onClickAway?: () => void
}

const Modal = ({ children, onClickAway, size = 'default' }: ModalProps) => {
  useEffect(() => {
    const body = document.body

    if (body) {
      body.style.overflowY = 'hidden'
    }

    return () => {
      if (body) {
        body.style.overflowY = 'unset'
      }
    }
  }, [])

  return (
    <Wrapper>
      <Overlay onClick={onClickAway} />
      <ContentWrapper size={size}>{children}</ContentWrapper>
    </Wrapper>
  )
}

export default Modal
