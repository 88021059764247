import { ReactNode } from 'react'
import styled from 'styled-components/macro'

import CloseButton, { StyledCloseButtonProps } from './CloseButton'

const StyledHeader = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacings[14]};
  padding-bottom: ${({ theme }) => theme.spacings[6]};

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    padding-top: ${({ theme }) => theme.spacings[15]};
    padding-bottom: ${({ theme }) => theme.spacings[8]};
  }
`

export type HeaderProps = {
  variant?: StyledCloseButtonProps['color']
  children: ReactNode
  onClick?: () => void
}

const Header = ({ variant = 'light', children, onClick }: HeaderProps) => {
  return (
    <StyledHeader>
      {children}
      <CloseButton color={variant} onClick={onClick} />
    </StyledHeader>
  )
}

export default Header
