import { forwardRef, InputHTMLAttributes, MouseEvent } from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  position: relative;
`

const RadioShapesContainer = styled.div`
  position: relative;
  display: flex;
`

const Circle = styled.span`
  content: '';
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.grey[300]};
  display: inline-block;
  width: 16px;
  height: 16px;
  top: 2px;
  margin-right: ${({ theme }) => theme.spacings[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    width: 20px;
    height: 20px;
    top: 4px;
  }
`
const Dot = styled.span`
  content: '';
  border-radius: 50%;
  position: absolute;
  display: inline-block;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  transition: opacity 150ms ease;
  opacity: 0;
  background-color: ${({ theme }) => theme.colors.black[500]};

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    left: 5px;
    width: 10px;
    height: 10px;
  }
`

const RadioLabel = styled.label`
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: flex-start;
`

const RadioInput = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;

  &:checked + ${RadioLabel} > ${RadioShapesContainer} > ${Dot} {
    opacity: 1;
  }
`

export type RadioProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'value'
> & {
  value?: string | number
  onWrapperClick?: (ev?: MouseEvent) => void
}

const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ children, onWrapperClick, ...rest }, ref) => {
    return (
      <Wrapper onClick={(e) => onWrapperClick?.(e)}>
        <RadioInput
          {...rest}
          id={rest.id ?? rest.value?.toString()}
          ref={ref}
        />

        <RadioLabel htmlFor={rest.id ?? rest.value?.toString()}>
          <RadioShapesContainer>
            <Circle />
            <Dot />
          </RadioShapesContainer>
          <span>{children}</span>
        </RadioLabel>
      </Wrapper>
    )
  }
)

export default styled(Radio)``
