import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components/macro'

import { getCoefficientMajoration } from '../../../queries/getCoefficientMajoration'
import { getDay } from '../../../queries/getDay'
import { getHoliday } from '../../../queries/getHoliday'
import { getHolidays } from '../../../queries/getHolidays'
import { formatDate, isTheSameDay } from '../../../utils/date'
import { getMajoratedPrice } from '../../../utils/price'
import Button from '../../atoms/button/Button'
import Spinner from '../../atoms/Spinner'
import Header from '../../molecules/header/Header'
import { SelectedDate } from '../AppointmentCalendarIntervention'
import CalendarHeader from '../CalendarHeader'
import { BoldText } from './ModalAssurance'

const Wrapper = styled.div`
  max-width: ${({ theme }) => `calc(360px + ${theme.spacings[12]})`};
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
`

const InformationWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => `0 ${theme.spacings[6]} ${theme.spacings[6]}`};

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    max-width: 872px;
    margin: 0 auto;
    padding: ${({ theme }) => `0 ${theme.spacings[6]} ${theme.spacings[8]}`};
  }
`

const DateListWrapper = styled(InformationWrapper)`
  position: relative;
  padding-top: ${({ theme }) => theme.spacings[6]};
  flex: 1;

  & > * {
    height: 85px;
  }

  & > * + *  {
    margin-top: ${({ theme }) => theme.spacings[4]};
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 100%;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.colors.grey[100]};
    z-index: ${({ theme }) => theme.zIndex.background};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    padding-top: ${({ theme }) => theme.spacings[15]};
    padding-bottom: ${({ theme }) => theme.spacings[15]};
  }
`

const SpinnerWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacings[6]};
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    top: ${({ theme }) => theme.spacings[15]};
  }
`

const TimeSlotP = styled.p`
  font-size: 14px;
`

const Majoration = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.primary[500]};
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
`

export type ModalCalendarProps = {
  onClose: () => void
  setDates: Dispatch<SetStateAction<SelectedDate[]>>
  dates: SelectedDate[]
  teleconsulting?: boolean
  price: number
}

export type TimeSlot = {
  value: string
  isIncrease?: boolean
  id: string
}

export type HolidayDate = {
  date: string
}

const ModalCalendar = ({
  onClose,
  setDates,
  dates,
  teleconsulting,
  price,
}: ModalCalendarProps) => {
  const [coefficientMajoration, setCoefficientMajoration] = useState(0)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [timeSlots, setTimeSlots] = useState<TimeSlot[] | null>(null)
  const [holidays, setHolidays] = useState<HolidayDate[] | null>(null)

  const selectTimeSlot = useCallback(
    (slot: TimeSlot) => {
      if (!selectedDate) return

      const newDateToSave: SelectedDate = {
        date: formatDate('full').format(new Date(selectedDate)),
        hours: slot.value,
        majoration: !!slot.isIncrease,
        _rawDate: selectedDate,
        id: slot.id,
      }

      setDates((prev) => [...prev, newDateToSave])
      onClose()
    },
    [setDates, selectedDate, onClose]
  )

  const checkSlotAvailability = useCallback(
    (el: TimeSlot) => {
      const filtereDates = dates.filter(
        (date) =>
          date._rawDate?.toString() === selectedDate?.toString() &&
          el.value === date.hours
      )

      return filtereDates.length > 0
    },
    [dates, selectedDate]
  )

  useEffect(() => {
    getCoefficientMajoration().then((coefficient) =>
      setCoefficientMajoration(coefficient)
    )
  }, [])

  useEffect(() => {
    getHolidays(setHolidays)
  }, [])

  useEffect(() => {
    if (!selectedDate) return

    setTimeSlots(null)

    const isHoliday = isTheSameDay(holidays, selectedDate)

    if (isHoliday) {
      getHoliday(setTimeSlots, teleconsulting)
    } else {
      const weekday = formatDate('weekday').format(new Date(selectedDate))
      getDay(weekday, setTimeSlots, teleconsulting)
    }
  }, [holidays, selectedDate, teleconsulting])

  return (
    <Wrapper>
      <InformationWrapper>
        <Header variant="dark" onClick={onClose}>
          <BoldText>Ajouter une disponibilité</BoldText>
        </Header>

        <CalendarHeader
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </InformationWrapper>
      <DateListWrapper>
        {timeSlots &&
          timeSlots.map((el, i) => {
            const isSlotAvailable = checkSlotAvailability(el)

            return (
              !isSlotAvailable && (
                <Button
                  color="white"
                  key={i}
                  onClick={() => selectTimeSlot(el)}
                >
                  <TimeSlotP>
                    {el.value}
                    {el.isIncrease && (
                      <Majoration>
                        Majoration +{' '}
                        {getMajoratedPrice(price, coefficientMajoration)}
                      </Majoration>
                    )}
                  </TimeSlotP>
                </Button>
              )
            )
          })}
        {!timeSlots && (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )}
      </DateListWrapper>
    </Wrapper>
  )
}

export default ModalCalendar
