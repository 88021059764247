import { airtable } from '../airtable'
import { MenuItemValue } from '../components/molecules/menu/Menu'
import { Choice } from '../sdk/Choice'

export function getDescribeLeakChoices(
  table: string,
  callback: (val: MenuItemValue[]) => void
) {
  airtable<Choice>(table)
    .select({
      view: 'Grid view',
    })
    .eachPage(
      function page(records, fetchNextPage) {
        callback(
          records.map((r) => ({
            value: r.get('key'),
            label: r.get('label'),
          }))
        )
        fetchNextPage()
      },
      function done(err) {
        if (err) {
          console.error(err)
          return
        }
      }
    )
}
