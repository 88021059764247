import { useEffect } from 'react'
import styled from 'styled-components/macro'

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowRight.svg'
import Button from '../../components/atoms/button/Button'
import Title from '../../components/atoms/title/Title'
import { useAppointmentForm } from '../../contexts/AppointmentForm'
import LocalStorageKeys from '../../localStorageKeys'

const PageWrapper = styled.div`
  width: 100%;
  padding: 3rem 0;
  ${Title} {
    margin-bottom: 3rem;
  }

  ${Button} {
    margin-top: 3rem;
    max-width: 264px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    padding: ${({ theme }) =>
      `${theme.spacings[25]} 2rem ${theme.spacings[25]} 0`};
    margin-left: ${({ theme }) => theme.spacings[4]};
    max-width: 552px;

    ${Title} {
      max-width: 440px;
      font-size: clamp(40px, 7vw, 48px);
    }
  }
`

const Wrapper = styled.div`
  max-width: 440px;
  margin: 0 auto;
`

const Text = styled.p`
  color: ${({ theme }) => theme.colors.black[400]};
`

const AppointmentSuccess = () => {
  const { appointmentFormData, saveData } = useAppointmentForm()

  useEffect(() => {
    if (
      !appointmentFormData?.interventionDates &&
      !appointmentFormData?.teleconsultationDates
    )
      return

    localStorage.setItem(LocalStorageKeys.totalPrice, '')
    localStorage.setItem(LocalStorageKeys.describeLeakForm, '')
    localStorage.setItem(LocalStorageKeys.prestationPrice, '')
    localStorage.setItem(LocalStorageKeys.answer, '')
    localStorage.setItem(LocalStorageKeys.priceParking, '')

    if (appointmentFormData) {
      const clearedAppointmentFormData = appointmentFormData
      clearedAppointmentFormData.teleconsultationDates = undefined
      clearedAppointmentFormData.interventionDates = undefined
      clearedAppointmentFormData.parking = undefined
      clearedAppointmentFormData.majoration = undefined

      saveData?.(clearedAppointmentFormData)
    }
  }, [appointmentFormData, saveData])

  return (
    <PageWrapper>
      <Wrapper>
        <Title>Votre demande d’intervention a bien été prise en compte</Title>
        <Text>
          Vous recevrez prochainement un email de confirmation pour votre
          demande.
        </Text>
        <Button
          href={process.env.REACT_APP_CDF_WEBSITE_URL || ''}
          icon={<ArrowIcon />}
        >
          Revenir au site
        </Button>
      </Wrapper>
    </PageWrapper>
  )
}

export default AppointmentSuccess
