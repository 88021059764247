import { ReactNode } from 'react'
import styled from 'styled-components/macro'

import Container from '../components/atoms/Container'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black[500]};
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  > * {
    flex: 1;
  }
`

type LayoutBlackProps = {
  children: ReactNode
}

const LayoutBlack = ({ children }: LayoutBlackProps) => {
  return (
    <Wrapper>
      <Container>{children}</Container>
    </Wrapper>
  )
}

export default LayoutBlack
