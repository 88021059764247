import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { useCombobox, UseComboboxStateChange } from 'downshift'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import styled, { css } from 'styled-components/macro'
import * as yup from 'yup'

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowRight.svg'
import Button from '../../components/atoms/button/Button'
import Card from '../../components/atoms/card/Card'
import Input from '../../components/atoms/input/Input'
import Title from '../../components/atoms/title/Title'
import FormGroup from '../../components/molecules/formGroup/FormGroup'
import MiniBreadcrumb from '../../components/molecules/miniBreadcrumb/MiniBreadcrumb'
import RadioGroup from '../../components/molecules/radioGroup/RadioGroup'
import { useAppointmentForm } from '../../contexts/AppointmentForm'
import LocalStorageKeys from '../../localStorageKeys'
import Routes from '../../router/Routes'

const PageWrapper = styled.div`
  width: 100%;
  padding: 3rem 0;

  ${Title} {
    margin-top: 1.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    padding: ${({ theme }) =>
      `${theme.spacings[20]} 2rem ${theme.spacings[20]} 0`};
    margin-left: ${({ theme }) => theme.spacings[4]};
    max-width: 552px;

    ${Title} {
      max-width: 455px;
      font-size: clamp(40px, 7vw, 48px);
    }
  }
`

const StyledForm = styled.form`
  margin-top: 2rem;

  ${Button} {
    margin-top: 1.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    margin-top: 4em;

    ${Button} {
      width: auto;
      min-width: 240px;
    }
  }
`

export const Select = styled.div`
  position: relative;

  div[class*='Input__Wrapper'] {
    z-index: 3;
  }
`

export const StyledOptions = styled.ul`
  list-style: none;
  padding: 16px 16px 8px;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.white[500]};
  border: 1px solid ${({ theme }) => theme.colors.grey[300]};
  border-radius: 5px;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(calc(100% - 12px));
  z-index: 1;
`

export const StyledOption = styled.li<{
  isHighlighted: boolean
}>`
  padding: 8px;
  border-radius: 3px;

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      background-color: ${({ theme }) => theme.colors.grey[100]};
    `}
`

const MajorationText = styled.p`
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
`

type AppointmentInterventionForm = {
  address: string
  freeParking: string
}

const schema = yup.object().shape({
  address: yup.string().required(),
})

type Option = {
  label: string
  score: string
  housenumber: string
  id: string
  name: string
  postcode: string
  citycode: string
  x: number
  y: number
  city: string
  context: string
  type: string
  importance: number
  street: string
}

const AppointmentIntervention = () => {
  const history = useHistory()
  const { appointmentFormData, saveData } = useAppointmentForm()

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<AppointmentInterventionForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      address: appointmentFormData?.address,
      freeParking: appointmentFormData?.parking ? 'false' : 'true',
    },
  })

  const [options, setOptions] = useState<Option[]>([])

  useEffect(() => {
    const postalCode = localStorage.getItem(LocalStorageKeys.postalCode)
    if (!postalCode) history.push(Routes.DescribeLeak)
  }, [history])

  const city = localStorage.getItem(LocalStorageKeys.city)
  const postalCode = localStorage.getItem(LocalStorageKeys.postalCode)

  const handleChange = async ({
    inputValue,
  }: UseComboboxStateChange<Option>) => {
    if (!inputValue) {
      setOptions([])
      setValue('address', '')
      return
    }

    const res = await axios.get(
      `https://api-adresse.data.gouv.fr/search/?q=${new URLSearchParams(`
        ${inputValue} ${city} ${postalCode}`)}&type=housenumber&autocomplete=1`
    )

    if (res.data) {
      setOptions(res.data.features.map((el: any) => el.properties))
      setValue('address', inputValue)
    }
  }

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    initialInputValue: appointmentFormData?.address,
    items: options,
    onInputValueChange: handleChange,
    itemToString: (item) => (item ? item.name : ''),
  })

  const onSubmit = handleSubmit((data) => {
    const postalCode = localStorage.getItem(LocalStorageKeys.postalCode)
    const city = localStorage.getItem(LocalStorageKeys.city)
    const priceParking = localStorage.getItem(LocalStorageKeys.priceParking)
    if (!postalCode || !priceParking) return

    saveData?.({
      address: data.address,
      postalCode: `${postalCode} - ${city}`,
      parking: data.freeParking === 'false' ? Number(priceParking) : undefined,
      majoration:
        data.freeParking === 'false' ? Number(priceParking) : undefined,
    })

    history.push(Routes.AppointmentCalendar)
  })

  return (
    <PageWrapper>
      <MiniBreadcrumb>
        <MiniBreadcrumb.Item to={Routes.AppointmentIntervention} active>
          Adresse
        </MiniBreadcrumb.Item>
        <MiniBreadcrumb.Item>Créneaux</MiniBreadcrumb.Item>
      </MiniBreadcrumb>

      <Title>Quelle est l'adresse de l'intervention ?</Title>

      <StyledForm onSubmit={onSubmit}>
        <FormGroup error={errors.address?.message}>
          <FormGroup.Label variant="small">Adresse *</FormGroup.Label>
          <Select {...getComboboxProps()}>
            <Input
              {...register('address')}
              error={!!errors.address}
              {...getInputProps()}
            />
            <div {...getMenuProps()}>
              {isOpen && options.length > 0 && (
                <StyledOptions>
                  {options.map((item, index) => (
                    <StyledOption
                      isHighlighted={highlightedIndex === index}
                      key={`${item.name}${index}`}
                      {...getItemProps({ item, index })}
                    >
                      {item.name}
                    </StyledOption>
                  ))}
                </StyledOptions>
              )}
            </div>
          </Select>
        </FormGroup>
        <FormGroup>
          <FormGroup.Label variant="small">Code postal *</FormGroup.Label>
          <Input
            readOnly
            value={`${localStorage.getItem(
              LocalStorageKeys.postalCode
            )} - ${localStorage.getItem(LocalStorageKeys.city)}`}
          />
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>
            Disposez vous d'une place gratuite pour que le technicien se gare ?
          </FormGroup.Label>

          <RadioGroup
            name="freeParking"
            value={watch('freeParking')}
            onChange={(val) => setValue('freeParking', val)}
          >
            <RadioGroup.Radio value="true">Oui</RadioGroup.Radio>
            <RadioGroup.Radio value="false">Non</RadioGroup.Radio>
          </RadioGroup>
        </FormGroup>

        {watch('freeParking') === 'false' && (
          <Card color="grey">
            <MajorationText>
              Une majoration de{' '}
              {new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
              }).format(
                Number(localStorage.getItem(LocalStorageKeys.priceParking)) || 0
              )}{' '}
              s’applique pour couvrir les frais de stationnement de notre
              technicien
            </MajorationText>
          </Card>
        )}

        <Button type="submit" icon={<ArrowIcon />}>
          Valider
        </Button>
      </StyledForm>
    </PageWrapper>
  )
}

export default AppointmentIntervention
