import { airtable } from '../airtable'
import { TimeSlot } from '../components/organisms/modals/ModalCalendar'
import { Day } from '../sdk/Day'

export const getDay = (
  selectedWeekday: string,
  callback: (val: TimeSlot[]) => void,
  teleconsulting?: boolean
) => {
  airtable<Day>(
    teleconsulting ? `Téléconsultation ${selectedWeekday}` : selectedWeekday
  )
    .select({
      view: 'Grid view',
      maxRecords: 1000,
    })
    .eachPage(
      function page(records, fetchNextPage) {
        callback(
          records.map((r) => {
            return {
              value: r.get('time_slot'),
              isIncrease: r.get('price_increase'),
              id: r.id,
            }
          })
        )
        fetchNextPage()
      },
      function done(err) {
        if (err) {
          console.error(err)
          return
        }
      }
    )
}
