export const breakpointsValues = {
  'mobile-sm': 320,
  'mobile-md': 375,
  'mobile-lg': 425,
  tablet: 768,
  'laptop-sm': 1024,
  'laptop-md': 1176,
}

const breakpoints = {
  'mobile-sm': `${breakpointsValues['mobile-sm']}px`,
  'mobile-md': `${breakpointsValues['mobile-md']}px`,
  'mobile-lg': `${breakpointsValues['mobile-lg']}px`,
  tablet: `${breakpointsValues.tablet}px`,
  'laptop-sm': `${breakpointsValues['laptop-sm']}px`,
  'laptop-md': `${breakpointsValues['laptop-md']}px`,
}

export default breakpoints
