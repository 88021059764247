import styled from 'styled-components/macro'

import Title from '../../atoms/title/Title'
import DualCards from '../../molecules/dualCards/DualCards'
import Header from '../../molecules/header/Header'

export const ModalPageWrapper = styled.div`
  width: 100%;
  display: block;
`

export const BoldText = styled.p`
  font-weight: 500;
  padding: ${({ theme }) => `${theme.spacings[3]} 0`};
`

export const InformationWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => `0 ${theme.spacings[6]}`};

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    max-width: 872px;
    margin: 0 auto;
  }
`

export const HeadingWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacings[6]} 0 ${theme.spacings[18]} 0`};

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    padding-top: ${({ theme }) => theme.spacings[28]};
    max-width: 760px;
  }
`

const HeadingDescription = styled.p`
  margin-top: ${({ theme }) => theme.spacings[11]};
  width: 100%;
  color: ${({ theme }) => theme.colors.black[400]};

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    margin-top: ${({ theme }) => theme.spacings[12]};
    max-width: 536px;
  }
`

const GreyText = styled.p`
  color: ${({ theme }) => theme.colors.black[400]};
`

export type ModalAssuranceProps = {
  title: string
  subtitle: string
  card1Title: string
  card1Content: string
  card2Title: string
  card2Content: string
  onClose: () => void
}

const ModalAssurance = ({
  title,
  subtitle,
  card1Title,
  card1Content,
  card2Title,
  card2Content,
  onClose,
}: ModalAssuranceProps) => {
  return (
    <ModalPageWrapper>
      <InformationWrapper>
        <Header variant="dark" onClick={onClose}>
          <BoldText>Prise en charge</BoldText>
        </Header>

        <HeadingWrapper>
          <Title
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <HeadingDescription>{subtitle}</HeadingDescription>
        </HeadingWrapper>
      </InformationWrapper>

      <DualCards title="Comment ça marche ?">
        <DualCards.Item title={card1Title}>
          <GreyText>{card1Content}</GreyText>
        </DualCards.Item>
        <DualCards.Item color="primary" title={card2Title}>
          <GreyText>{card2Content}</GreyText>
        </DualCards.Item>
      </DualCards>
    </ModalPageWrapper>
  )
}

export default ModalAssurance
