import { useEffect } from 'react'
import { ThemeProvider } from 'styled-components/macro'

import Router from './router/Router'
import theme, { GlobalStyle } from './theme/theme'

function App() {
  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    window.addEventListener('load', setVh)
    window.addEventListener('resize', setVh)
  }, [])

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </>
  )
}

export default App
