import styled, { css } from 'styled-components/macro'

import Button from '../../atoms/button/Button'

/*--------------- MAIN CONTENT ---------------*/
export const PageWrapper = styled.div`
  width: 100%;
  display: grid;

  grid-template-areas:
    'header'
    'content'
    'information';

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    min-height: 100vh;
    height: 100%;
    grid-template-rows: 0.3fr 1fr;
    grid-template-areas:
      'header content'
      'information content';
  }
`

export const InformationWrapper = styled.div`
  grid-area: information;
  width: 100%;
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.spacings[8]};

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    margin-top: clamp(
      ${({ theme }) => theme.spacings[8]},
      4vw,
      ${({ theme }) => theme.spacings[32]}
    );
    padding-right: ${({ theme }) => theme.spacings[6]};
    align-self: start;
  }
`

export const HeaderWrapper = styled.div`
  grid-area: header;
  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    width: 100%;
    padding-right: ${({ theme }) => theme.spacings[6]};

    button {
      display: none;
    }
  }
`

export const CloseWrapperDesktop = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    display: initial;
    position: absolute;
    right: 0;
    padding-top: ${({ theme }) => theme.spacings[15]};
    margin-right: ${({ theme }) => theme.spacings[15]};
  }
`

export const ContentWrapper = styled.div`
  grid-area: content;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacings[12]} 0`};
  margin: 0 auto;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    height: 100%;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.colors.grey[100]};
    z-index: ${({ theme }) => theme.zIndex.background};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    max-width: 300px;

    &::before {
      transform: translateX(0);
      left: 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints['laptop-sm']}) {
    max-width: 455px;
  }
`

export const InnerContent = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 360px;
    margin-left: auto;
    padding: ${({ theme }) => `0 ${theme.spacings[4]}`};
  }
`
/*--------------------------------------*/

/*--------------- LEFT -----------------*/
export const HeadingWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacings[6]} 0 ${theme.spacings[18]} 0`};

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    max-width: 575px;
  }
`

export const HeadingDescription = styled.p`
  margin: ${({ theme }) => `${theme.spacings[11]} 0 ${theme.spacings[6]} 0`};
  width: 100%;
  color: ${({ theme }) => theme.colors.black[400]};

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    margin: ${({ theme }) => `${theme.spacings[8]} 0 ${theme.spacings[12]} 0`};
    max-width: 455px;
  }
`
/*---------------------------------------------*/

/*--------------- RIGHT PRICE -----------------*/
export const CardWrapper = styled.div`
  margin: ${({ theme }) => `${theme.spacings[12]} 0`};

  & > * + * {
    margin-top: ${({ theme }) => theme.spacings[2]};
  }
`

export const CardTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
`

export const StyledCard = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  & svg {
    cursor: pointer;
    flex: 0 0 auto;
    margin-left: ${({ theme }) => theme.spacings[6]};
  }
`

export const SmallText = css`
  color: ${({ theme }) => theme.colors.black[400]};
  font-size: 14px;
  font-weight: 400;
`

export const CardInfo = styled.p`
  ${SmallText}
  margin-top: ${({ theme }) => theme.spacings[6]};
`

export const Price = styled.p`
  font-size: 19px;
  line-height: 1.3;
  font-weight: 500;
`

export const SubPrice = styled.span`
  ${SmallText}
  display: block;
  margin-top: ${({ theme }) => theme.spacings[2]};
`
/*----------------------------------------------*/

/*--------------- RIGHT POSTAL -----------------*/
export const Description = styled.p`
  color: ${({ theme }) => theme.colors.black[500]};
`

export const ZipInfo = styled.p`
  margin-bottom: ${({ theme }) => theme.spacings[2]};
`

export const PostalWrapper = styled.div`
  margin-bottom: 4rem;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  gap: 1rem;
`

export const Select = styled.div`
  position: relative;

  div[class*='Input__Wrapper'] {
    z-index: 3;
  }
`

export const SubmitButton = styled(Button)`
  margin-top: 2rem;
  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    width: auto;
  }
`

export const Label = styled.label`
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.spacings[3]};
`

export const StyledOptions = styled.ul`
  list-style: none;
  padding: 16px 16px 8px;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.white[500]};
  border: 1px solid ${({ theme }) => theme.colors.grey[300]};
  border-radius: 5px;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(calc(100% - 12px));
  z-index: 1;
`

export const StyledOption = styled.li<{
  isHighlighted: boolean
}>`
  padding: 8px;
  border-radius: 3px;

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      background-color: ${({ theme }) => theme.colors.grey[100]};
    `}
`
/*----------------------------------------------*/
