import { airtable } from '../airtable'
import { Answer } from '../sdk/Answer'

export async function getAnswerByKey(key: string) {
  const answers = await airtable<Answer>('Réponses')
    .select({
      view: 'Grid view',
      filterByFormula: `{key} = "${key}"`,
    })
    .all()

  return answers?.[0]?.fields
}
