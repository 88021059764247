const spacings = {
  1: '0.25rem',
  2: '0.5rem',
  3: '0.75rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
  11: '2.75rem',
  12: '3rem',
  14: '3.5rem',
  15: '3.75rem',
  16: '4rem',
  18: '4.5rem',
  20: '5rem',
  22: '5.5rem',
  23: '5.75rem',
  24: '6rem',
  25: '6.5rem',
  28: '7rem',
  32: '8rem',
  36: '9rem',
  38: '9.75rem',
  40: '10rem',
}
export default spacings
