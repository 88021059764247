import { ReactNode } from 'react'

import Container from '../components/atoms/Container'

type DefaultLayoutProps = {
  children: ReactNode
}

const DefaultLayout = ({ children }: DefaultLayoutProps) => {
  return <Container>{children}</Container>
}

export default DefaultLayout
