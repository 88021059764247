import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { StripeCardElement, StripeCardElementOptions } from '@stripe/stripe-js'
import { useState, useEffect, FormEvent } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router'
import Stripe from 'stripe'
import styled from 'styled-components/macro'

import { MailjetVariables, Detail, Slot } from '../../../netlify/functions/mail'
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowRight.svg'
import { useAppointmentForm } from '../../contexts/AppointmentForm'
import LocalStorageKeys from '../../localStorageKeys'
import { FlexContainer } from '../../pages/appointment/AppointmentContact'
import { getCoefficientMajoration } from '../../queries/getCoefficientMajoration'
import { getTeleconsultationPrice } from '../../queries/getTeleconsultationPrice'
import Routes from '../../router/Routes'
import { Answer } from '../../sdk/Answer'
import theme from '../../theme/theme'
import { isProduction } from '../../utils/isProduction'
import { getMajoratedPrice, formatPrice } from '../../utils/price'
import Button from '../atoms/button/Button'
import Spinner from '../atoms/Spinner'
import FormGroup from '../molecules/formGroup/FormGroup'

const StyledForm = styled.form`
  ${Button} {
    margin-top: 1.5rem;

    @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
      width: auto;
      min-width: 240px;
    }
  }
`

const CustomInputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.grey[300]};

  * {
    width: 100%;
  }
`

const StyledError = styled.p`
  color: ${({ theme }) => theme.colors.primary[500]};
`

const SpinnerWrapper = styled.div`
  margin-top: 1.5rem;
`

const INPUT_OPTIONS: StripeCardElementOptions = {
  style: {
    base: {
      color: theme.colors.black[500],
      backgroundColor: 'transparent',

      '::placeholder': {
        color: theme.colors.black[400],
      },
    },
    invalid: {
      color: theme.colors.danger[500],
    },
  },
}

export type CheckoutFormProps = {
  paymentIntent: Stripe.Response<Stripe.PaymentIntent>
}

export const FormSkeleton = () => (
  <FormGroup>
    <FormGroup.Label variant="small">
      <Skeleton />
    </FormGroup.Label>
    <Skeleton height="66px" />
  </FormGroup>
)

const CheckoutForm = ({ paymentIntent }: CheckoutFormProps) => {
  const history = useHistory()
  const { appointmentFormData } = useAppointmentForm()
  const stripe = useStripe()
  const elements = useElements()

  const [teleconsultationPrice, setTeleconsultationPrice] = useState(0)
  const [coefficientMajoration, setCoefficientMajoration] = useState(0)
  const [error, setError] = useState<string>()
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    getTeleconsultationPrice().then((price) => setTeleconsultationPrice(price))
    getCoefficientMajoration().then((coefficient) =>
      setCoefficientMajoration(coefficient)
    )
  }, [])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!paymentIntent?.client_secret || !elements) return
    if (!elements.getElement(CardNumberElement)) return

    try {
      setProcessing(true)
      const response = await stripe?.confirmCardPayment(
        paymentIntent.client_secret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement) as StripeCardElement,
            billing_details: {
              email: appointmentFormData?.email,
              name: `${appointmentFormData?.firstname} ${appointmentFormData?.lastname}`,
              address: {
                city: appointmentFormData?.address,
                postal_code: appointmentFormData?.postalCode,
              },
            },
          },
        }
      )
      setProcessing(false)

      if (response?.error) {
        setError(response.error.message)
      }

      if (response?.paymentIntent?.status === 'requires_capture') {
        const intervention: Answer = JSON.parse(
          localStorage.getItem(LocalStorageKeys.answer)!
        )
        const details: Detail[] = []

        if (appointmentFormData?.teleconsultationDates) {
          details.push({
            title: 'Téléconsultation',
            price: formatPrice(teleconsultationPrice),
            red: false,
          })

          if (appointmentFormData?.interventionDates) {
            details.push({
              title: 'Remboursement téléconsultation*',
              price: `- ${formatPrice(teleconsultationPrice)}`,
              red: true,
            })
          }
        }

        if (appointmentFormData?.interventionDates) {
          details.push({
            title: 'Intervention sur place',
            price: formatPrice(
              Number(localStorage.getItem(LocalStorageKeys.prestationPrice))
            ),
            red: false,
          })
        }

        let more: MailjetVariables['more'] = []

        if (
          appointmentFormData?.teleconsultationDates?.find(
            (date) => date.majoration
          )
        ) {
          more.push({
            title: 'Majoration du créneau horaire de la téléconsultation',
            price: `+ ${getMajoratedPrice(
              teleconsultationPrice,
              coefficientMajoration
            )}`,
          })
        }

        if (
          appointmentFormData?.interventionDates?.find(
            (date) => date.majoration
          )
        ) {
          more.push({
            title: 'Majoration du créneau horaire de l’intervention',
            price: `+ ${getMajoratedPrice(
              Number(localStorage.getItem(LocalStorageKeys.prestationPrice)),
              coefficientMajoration
            )}`,
          })
        }

        if (
          appointmentFormData?.interventionDates &&
          appointmentFormData?.parking
        ) {
          more.push({
            title: 'Stationnement du technicien',
            price: `+ ${formatPrice(appointmentFormData.parking)}`,
          })
        }

        const mailjetVariables: MailjetVariables = {
          user: {
            name:
              `${appointmentFormData?.firstname} ${appointmentFormData?.lastname}` ||
              '',
            address: appointmentFormData?.address || '',
            email: appointmentFormData?.email || '',
            zip: appointmentFormData?.postalCode || '',
            phone: appointmentFormData?.phone || '',
          },
          intervention,
          interventionsDates: appointmentFormData?.interventionDates?.map(
            ({ date, hours, majoration }) => {
              const intervention: Slot = {
                date,
                hours,
              }

              if (majoration) {
                intervention.majoration = getMajoratedPrice(
                  Number(
                    localStorage.getItem(LocalStorageKeys.prestationPrice)
                  ),
                  coefficientMajoration
                )
              }

              return intervention
            }
          ),
          teleconsultations: appointmentFormData?.teleconsultationDates?.map(
            ({ date, hours, majoration }) => {
              const teleconsultation: Slot = {
                date,
                hours,
              }

              if (majoration) {
                teleconsultation.majoration = getMajoratedPrice(
                  teleconsultationPrice,
                  coefficientMajoration
                )
              }

              return teleconsultation
            }
          ),
          details,
          more: more.length > 0 ? more : undefined,
          total: formatPrice(
            Number(localStorage.getItem(LocalStorageKeys.totalPrice))
          ),
        }

        await fetch(
          isProduction
            ? process.env.REACT_APP_NETLIFY_FUNCTION_PROD_URL + '/mail'
            : process.env.REACT_APP_NETLIFY_FUNCTION_DEV_URL + '/mail',
          {
            method: 'POST',
            body: JSON.stringify(mailjetVariables),
          }
        )

        history.push(Routes.AppointmentSuccess)
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <FormGroup>
        <FormGroup.Label variant="small">Numéro de carte</FormGroup.Label>
        <CustomInputWrapper>
          <CardNumberElement options={INPUT_OPTIONS} />
        </CustomInputWrapper>
      </FormGroup>

      <FlexContainer>
        <FormGroup>
          <FormGroup.Label variant="small">Date d’expiration</FormGroup.Label>
          <CustomInputWrapper>
            <CardExpiryElement options={INPUT_OPTIONS} />
          </CustomInputWrapper>
        </FormGroup>

        <FormGroup>
          <FormGroup.Label variant="small">CVV</FormGroup.Label>
          <CustomInputWrapper>
            <CardCvcElement options={INPUT_OPTIONS} />
          </CustomInputWrapper>
        </FormGroup>
      </FlexContainer>

      {error && <StyledError>{error}</StyledError>}

      {processing ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <Button type="submit" disabled={!stripe} icon={<ArrowIcon />}>
          Valider
        </Button>
      )}
    </StyledForm>
  )
}

export default CheckoutForm
