import { cloneElement, ReactElement } from 'react'
import styled from 'styled-components/macro'

import OrderedListItem, { OrderedListItemProps } from './OrderedListItem'

const Wrapper = styled.div`
  & > * + * {
    margin-top: ${({ theme }) => theme.spacings[5]};
  }
`

export type OrderedListProps = {
  children: ReactElement<OrderedListItemProps>[]
}

const OrderedList = ({ children }: OrderedListProps) => {
  return (
    <Wrapper>
      {children.map((child, index) =>
        cloneElement(child, {
          key: index,
          index: index + 1,
        })
      )}
    </Wrapper>
  )
}

OrderedList.Item = (props: Omit<OrderedListItemProps, 'index'>) => (
  <OrderedListItem {...props} />
)
export default OrderedList
