import { isAfter } from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components/macro'

import Modal from '../../components/atoms/modal/Modal'
import Title from '../../components/atoms/title/Title'
import MiniBreadcrumb from '../../components/molecules/miniBreadcrumb/MiniBreadcrumb'
import Availabilities from '../../components/organisms/Availabilities'
import ModalCalendar from '../../components/organisms/modals/ModalCalendar'
import ModalTeleconsulting from '../../components/organisms/modals/ModalTeleconsulting'
import { useAppointmentForm } from '../../contexts/AppointmentForm'
import LocalStorageKeys from '../../localStorageKeys'
import { getTeleconsultationPrice } from '../../queries/getTeleconsultationPrice'
import { getTexts } from '../../queries/getTexts'
import Routes from '../../router/Routes'
import { Text } from '../../sdk/Text'

export const PageWrapper = styled.div`
  width: 100%;
  padding: 3rem 0;

  ${Title} {
    margin-top: 1.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    padding: ${({ theme }) =>
      `${theme.spacings[20]} 2rem ${theme.spacings[20]} 0`};
    margin-left: ${({ theme }) => theme.spacings[4]};
    max-width: 552px;

    ${Title} {
      max-width: 455px;
      font-size: clamp(40px, 7vw, 48px);
    }
  }
`

export const Bold = styled.span`
  font-weight: 500;
`

export type SelectedDate = {
  date: string | null
  hours: string
  majoration: boolean
  _rawDate: Date
  id: string
}

const AppointmentCalendarIntervention = () => {
  const history = useHistory()
  const { appointmentFormData, saveData } = useAppointmentForm()

  const pageRef = useRef<HTMLDivElement | null>(null)

  const [texts, setTexts] = useState<Text[]>([])
  const [isModalTeleconsultingOpen, setModalTeleconsultingOpen] =
    useState(false)
  const [isModalCalendarOpen, setModalCalendarOpen] = useState(false)
  const [dates, setDates] = useState<SelectedDate[]>([])
  const [teleconsultationPrice, setTeleconsultationPrice] = useState('')
  const price = localStorage.getItem(LocalStorageKeys.prestationPrice)

  useEffect(() => {
    if (!price) {
      history.push(Routes.DescribeLeak)
    }
  }, [price, history])

  useEffect(() => {
    getTexts(['teleconsulting_title', 'teleconsulting_subtitle'], setTexts)
  }, [])

  const getText = (key: string) =>
    texts.find((t) => t.key === key)?.content || ''

  useEffect(() => {
    if (!appointmentFormData?.interventionDates) return

    const dates = appointmentFormData.interventionDates.filter((date) =>
      isAfter(new Date(date._rawDate), new Date())
    )

    setDates(dates)
  }, [appointmentFormData])

  useEffect(() => {
    getTeleconsultationPrice()
      .then((price) =>
        setTeleconsultationPrice(
          new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
          }).format(price)
        )
      )
      .catch((error) => console.error(error))
  }, [])

  useEffect(() => {
    pageRef.current?.scrollTo(0, pageRef.current.scrollHeight)
  }, [dates])

  const handleClick = () => {
    setModalTeleconsultingOpen(true)
    saveData?.({ interventionDates: dates })
  }

  return (
    <PageWrapper ref={pageRef}>
      <MiniBreadcrumb>
        <MiniBreadcrumb.Item to={Routes.AppointmentIntervention}>
          Adresse
        </MiniBreadcrumb.Item>
        <MiniBreadcrumb.Item to={Routes.AppointmentCalendar} active>
          Créneaux
        </MiniBreadcrumb.Item>
      </MiniBreadcrumb>
      <Title>À quelle heure notre technicien peut-il arriver ?</Title>

      <Availabilities
        description={
          <>
            Renseignez vos disponibilités{' '}
            <Bold>(2 créneaux minimum sur des jours différents)</Bold> et nous
            vous confirmerons votre rendez-vous par mail
          </>
        }
        dates={dates}
        setDates={setDates}
        setModalCalendarOpen={setModalCalendarOpen}
        price={Number(price) || 0}
        onClick={handleClick}
        ctaWidth="small"
      />

      {isModalTeleconsultingOpen && (
        <Modal onClickAway={() => setModalTeleconsultingOpen(false)}>
          <ModalTeleconsulting
            title={getText('teleconsulting_title')}
            subtitle={getText('teleconsulting_subtitle')}
            teleconsultationPrice={teleconsultationPrice}
            onClose={() => setModalTeleconsultingOpen(false)}
            showTeleconsultingCalendar
          />
        </Modal>
      )}
      {isModalCalendarOpen && (
        <Modal onClickAway={() => setModalCalendarOpen(false)} size="small">
          <ModalCalendar
            onClose={() => setModalCalendarOpen(false)}
            setDates={setDates}
            dates={dates}
            price={Number(price) || 0}
          />
        </Modal>
      )}
    </PageWrapper>
  )
}

export default AppointmentCalendarIntervention
