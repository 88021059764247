import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

const Wrapper = styled.div<Pick<OrderedListItemProps, 'active'>>`
  display: flex;
  align-items: flex-start;

  ${({ active }) =>
    active &&
    css`
      ${Index} {
        background-color: ${({ theme }) => theme.colors.primary[500]};
      }

      ${Content} {
        color: ${({ theme }) => theme.colors.primary[500]};
      }
    `}
`

const Index = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.black[500]};
  color: ${({ theme }) => theme.colors.white[500]};
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-size: 14px;
  line-height: 1;
`

const Content = styled.div`
  margin-left: ${({ theme }) => theme.spacings[4]};
  padding-top: 0.3rem;
`

export type OrderedListItemProps = {
  children: ReactNode
  index?: number
  active?: boolean
}

const OrderedListItem = ({ children, index, active }: OrderedListItemProps) => {
  return (
    <Wrapper active={active}>
      <div>
        <Index>{index}</Index>
      </div>
      <Content>{children}</Content>
    </Wrapper>
  )
}

export default OrderedListItem
