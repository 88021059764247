import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import theme from '../../../theme/theme'

export enum LabelVariant {
  small = 'small',
  default = 'default',
}

const labelVariants = {
  [LabelVariant.small]: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    margin-bottom: ${theme.spacings[2]};
  `,
  [LabelVariant.default]: css`
    line-height: 1.5;
    margin-bottom: ${theme.spacings[6]};
  `,
}

export type LabelProps = {
  variant?: keyof typeof LabelVariant
}

const Label = styled.label<LabelProps>`
  ${({ variant }) => labelVariants[variant || LabelVariant.default]}
`

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.danger[500]};
  margin-top: ${({ theme }) => theme.spacings[2]};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacings[6]};
`

export type FormGroupProps = {
  children: ReactNode
  className?: string
  error?: string
}

const FormGroup = ({ children, className, error }: FormGroupProps) => {
  return (
    <Wrapper className={className}>
      {children}
      <ErrorMessage>{error}</ErrorMessage>
    </Wrapper>
  )
}

FormGroup.Label = Label

export default styled(FormGroup)``
