export const APPOINTMENT_BASE_URL = '/appointment'

const Routes = {
  DescribeLeak: '/',
  ProposalService: '/proposal-service',
  AppointmentContact: `${APPOINTMENT_BASE_URL}/contact`,
  AppointmentCalendar: `${APPOINTMENT_BASE_URL}/calendar`,
  AppointmentIntervention: `${APPOINTMENT_BASE_URL}/intervention`,
  AppointmentValidation: `${APPOINTMENT_BASE_URL}/validation`,
  AppointmentPayment: `${APPOINTMENT_BASE_URL}/payment`,
  AppointmentSuccess: `${APPOINTMENT_BASE_URL}/success`,
}

export default Routes
