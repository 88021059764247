import { InputHTMLAttributes } from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Label = styled.label`
  font-weight: 500;
  line-height: 150%;
`

const CheckboxLabel = styled.label`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 32px;
`

const Toggle = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.grey[200]};
  cursor: pointer;
  transition: all 0.4s ease;
  border-radius: 34px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    background-color: white;
    transition: all 0.4s ease;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.grey[300]};
  }
`

const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  &:checked + ${Toggle} {
    background-color: ${({ theme }) => theme.colors.primary[500]};
  }

  &:checked + ${Toggle}::before {
    transform: translateX(32px);
  }
`

export type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>

const Checkbox = ({ children, ...rest }: CheckboxProps) => {
  return (
    <Wrapper>
      <Label>{children}</Label>
      <CheckboxLabel>
        <CheckboxInput hidden {...rest} />
        <Toggle />
      </CheckboxLabel>
    </Wrapper>
  )
}

export default Checkbox
