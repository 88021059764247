import { useAppointmentForm } from '../../../contexts/AppointmentForm'
import Routes from '../../../router/Routes'
import Button from '../../atoms/button/Button'
import Card from '../../atoms/card/Card'
import {
  CardHeading,
  Section,
  SectionTitle,
  TitleContent,
} from './ValidationStyled'

const ContactRecap = () => {
  const { appointmentFormData } = useAppointmentForm()

  return (
    <Section>
      <SectionTitle>
        <TitleContent>Contact</TitleContent>
        <Button to={Routes.AppointmentContact} shape="ghost">
          Modifier
        </Button>
      </SectionTitle>
      <Card withBorder>
        <CardHeading>
          {appointmentFormData?.firstname} {appointmentFormData?.lastname}
        </CardHeading>
        <p>{appointmentFormData?.email}</p>
        <p>{appointmentFormData?.phone}</p>
      </Card>
    </Section>
  )
}

export default ContactRecap
