import { useRef } from 'react'
import styled from 'styled-components/macro'

import { ReactComponent as CameraIcon } from '../../../assets/icons/teleconsulting/camera.svg'
import { ReactComponent as PinIcon } from '../../../assets/icons/teleconsulting/pin.svg'
import Title from '../../atoms/title/Title'
import DualCards from '../../molecules/dualCards/DualCards'
import Header from '../../molecules/header/Header'
import ModalTeleconsultationBenefits from '../ModalTeleconsultationBenefits'
import TeleconsultationSteps from '../TeleconsultationSteps'
import TeleconsultingCalendar from '../TeleconsultingCalendar'
import {
  BoldText,
  HeadingWrapper,
  InformationWrapper,
  ModalPageWrapper,
} from './ModalAssurance'

const TABLET_BREAKPOINT = 'tablet'

const SmallTitle = styled.p`
  font-size: 19px;
  font-weight: 500;
`

const HeadingInformation = styled(SmallTitle)`
  margin-top: ${({ theme }) => theme.spacings[6]};
`

const HeadingDescription = styled.p`
  margin-top: ${({ theme }) => theme.spacings[6]};
  width: 100%;
  color: ${({ theme }) => theme.colors.black[400]};

  @media (min-width: ${({ theme }) => theme.breakpoints[TABLET_BREAKPOINT]}) {
    margin-top: ${({ theme }) => theme.spacings[12]};
    max-width: 536px;
  }
`

type RowProps = {
  align: 'center' | 'start'
}

const Row = styled.div<RowProps>`
  display: flex;
  align-items: ${({ align }) => align};
  margin-bottom: ${({ theme }) => theme.spacings[6]};
`

const CardIcon = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.grey[100]};
  margin-right: ${({ theme }) => theme.spacings[6]};
`

const SmallText = styled.p`
  font-size: 14px;

  @media (min-width: ${({ theme }) => theme.breakpoints[TABLET_BREAKPOINT]}) {
    font-size: 16px;
  }
`

type PriceProps = {
  lineThrough?: boolean
}

const Price = styled(SmallText)<PriceProps>`
  margin-left: auto;
  font-weight: 500;
  position: relative;
  ${({ lineThrough }) => lineThrough && `text-decoration: line-through;`}

  & span {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    text-decoration: none;
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black[400]};
    transform: translateY(80%);
  }
`

export type ModalTeleconsultingProps = {
  title: string
  subtitle: string
  teleconsultationPrice: string
  onClose: () => void
  showTeleconsultingCalendar?: boolean
}

const ModalTeleconsulting = ({
  title,
  subtitle,
  teleconsultationPrice,
  onClose,
  showTeleconsultingCalendar,
}: ModalTeleconsultingProps) => {
  const pageRef = useRef<HTMLDivElement | null>(null)

  return (
    <ModalPageWrapper ref={pageRef}>
      <InformationWrapper>
        <Header variant="dark" onClick={onClose}>
          <BoldText>Téléconsultation</BoldText>
        </Header>

        <HeadingWrapper>
          <Title
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <HeadingInformation>
            Pour {teleconsultationPrice} seulement
          </HeadingInformation>
          <HeadingDescription>{subtitle}</HeadingDescription>
        </HeadingWrapper>
      </InformationWrapper>

      <TeleconsultationSteps />
      <ModalTeleconsultationBenefits
        teleconsultationPrice={teleconsultationPrice}
      />

      <DualCards title="Combien ça coute ?">
        <DualCards.Item title="Vous avez trouvé et réparé votre fuite en visioconférence grâce au technicien">
          <Row align="center">
            <CardIcon>
              <CameraIcon />
            </CardIcon>
            <SmallText>Téléconsultation</SmallText>
            <Price>{teleconsultationPrice}</Price>
          </Row>
          <Row align="start">
            <CardIcon>
              <PinIcon />
            </CardIcon>
            <SmallText>
              Votre rendez-vous pour une intervention sur place est annulé sans
              frais supplémentaire
            </SmallText>
          </Row>
        </DualCards.Item>
        <DualCards.Item
          color="primary"
          title="L’intervention sur place d’un technicien reste nécessaire pour trouver votre fuite"
        >
          <Row align="center">
            <CardIcon>
              <CameraIcon />
            </CardIcon>
            <SmallText>Téléconsultation</SmallText>
            <Price lineThrough={true}>
              {teleconsultationPrice} <span>remboursée</span>
            </Price>
          </Row>
          <Row align="start">
            <CardIcon>
              <PinIcon />
            </CardIcon>
            <SmallText>
              Votre rendez-vous pour une intervention sur place est maintenu (et
              déjà réservé)
            </SmallText>
          </Row>
        </DualCards.Item>
      </DualCards>

      {showTeleconsultingCalendar && (
        <TeleconsultingCalendar parentRef={pageRef} />
      )}
    </ModalPageWrapper>
  )
}

export default ModalTeleconsulting
