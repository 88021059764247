import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useCallback } from 'react'
import styled, { css } from 'styled-components/macro'

import { ReactComponent as AddIcon } from '../../assets/icons/add.svg'
import { ReactComponent as ArrowRight } from '../../assets/icons/arrowRight.svg'
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg'
import { getCoefficientMajoration } from '../../queries/getCoefficientMajoration'
import { getMajoratedPrice } from '../../utils/price'
import Button from '../atoms/button/Button'
import { SelectedDate } from './AppointmentCalendarIntervention'

const Description = styled.p`
  margin: ${({ theme }) => `${theme.spacings[12]} 0`};
`

const DatesWrapper = styled.div`
  font-size: 14px;

  & > * {
    font-size: 14px;
    cursor: default;

    svg {
      width: 15px;
      cursor: pointer;
    }
  }

  & > * + * {
    margin-top: ${({ theme }) => theme.spacings[6]};
  }

  & > *:last-child {
    cursor: pointer;
    svg {
      width: 20px;
    }
  }
`

const DateButton = styled.p`
  text-transform: capitalize;
`

const SlotSpan = styled.span`
  display: block;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black[400]};
  margin-top: 4px;
`

const MajoratedSpan = styled(SlotSpan)`
  color: ${({ theme }) => theme.colors.primary[500]};
`

type CTAWrapperProps = Pick<AvailabilitiesProps, 'ctaWidth'>

const CTAWrapper = styled.div<CTAWrapperProps>`
  margin-top: ${({ theme }) => theme.spacings[12]};
  width: 100%;

  ${({ ctaWidth }) =>
    ctaWidth === 'small' &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
        width: 264px;
      }
    `}
`

export type AvailabilitiesProps = {
  description: React.ReactNode
  dates: SelectedDate[]
  onClick: () => void
  ctaWidth?: 'small' | 'default'
  setModalCalendarOpen: (param: boolean) => void
  setDates: Dispatch<SetStateAction<SelectedDate[]>>
  price: number
  teleconsulting?: boolean
}

const Availabilities = ({
  dates,
  setDates,
  setModalCalendarOpen,
  description,
  price,
  teleconsulting,
  ctaWidth,
  onClick,
}: AvailabilitiesProps) => {
  const [coefficientMajoration, setCoefficientMajoration] = useState(0)

  useEffect(() => {
    getCoefficientMajoration().then((coefficient) =>
      setCoefficientMajoration(coefficient)
    )
  }, [])

  const handleClick = useCallback(
    (el: SelectedDate) => {
      const newDates = dates.filter((item) => item !== el)
      setDates(newDates)
    },
    [dates, setDates]
  )

  const checkDifferentDays = useCallback(() => {
    if (dates?.length < 2) return

    const rawDates = dates.map((e) => e._rawDate.toString())
    const setOfDates = new Set(rawDates)

    return setOfDates.size >= 2
  }, [dates])

  return (
    <>
      <Description>{description}</Description>

      <DatesWrapper>
        {dates.map((date, i) => (
          <Button
            key={i}
            color="white"
            shape="outline"
            icon={<CrossIcon onClick={() => handleClick(date)} />}
          >
            <DateButton>
              {date.date?.substring(0, date.date.length - 5)}
              <SlotSpan>{date.hours}</SlotSpan>
              {date.majoration && (
                <MajoratedSpan>
                  Majoration + {getMajoratedPrice(price, coefficientMajoration)}
                </MajoratedSpan>
              )}
            </DateButton>
          </Button>
        ))}

        <Button
          onClick={() => setModalCalendarOpen(true)}
          color="white"
          shape="outline"
          icon={<AddIcon />}
        >
          {teleconsulting && 'Choisir un créneau'}
          {!teleconsulting && 'Ajouter une disponibilité'}
        </Button>
      </DatesWrapper>

      <CTAWrapper ctaWidth={ctaWidth}>
        <Button
          icon={<ArrowRight />}
          disabled={!(dates?.length >= 2 && checkDifferentDays())}
          onClick={onClick}
        >
          Valider
        </Button>
      </CTAWrapper>
    </>
  )
}

export default Availabilities
