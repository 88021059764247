import { useEffect, useState } from 'react'

import { ReactComponent as Searchicon } from '../../../assets/icons/search.svg'
import { getTexts } from '../../../queries/getTexts'
import { Text } from '../../../sdk/Text'
import Card from '../../atoms/card/Card'
import Modal from '../../atoms/modal/Modal'
import ModalTeleconsulting from '../modals/ModalTeleconsulting'
import ModalZoneIntervention from '../modals/ModalZoneIntervention'
import {
  CardTitle,
  CardWrapper,
  Price,
  StyledCard,
  SubPrice,
} from './ProposalServiceStyled'

type RightContentProps = {
  price: string
  teleconsultationPrice: string
}

const RightContent = ({ price, teleconsultationPrice }: RightContentProps) => {
  const [isModalTeleconsultingOpen, setModalTeleconsultingOpen] =
    useState(false)
  const [isModalZoneInterventionOpen, setModalZoneInterventionOpen] =
    useState(false)
  const [texts, setTexts] = useState<Text[]>([])

  useEffect(() => {
    getTexts(['teleconsulting_title', 'teleconsulting_subtitle'], setTexts)
  }, [])

  const getText = (key: string) =>
    texts.find((t) => t.key === key)?.content || ''

  return (
    <div>
      <Price>
        À partir de {price}
        <SubPrice>Téléconsultation uniquement</SubPrice>
      </Price>
      <CardWrapper>
        <Card color="white">
          <StyledCard>
            <CardTitle>En savoir plus sur la téléconsultation</CardTitle>
            <Searchicon onClick={() => setModalTeleconsultingOpen(true)} />
          </StyledCard>
        </Card>
      </CardWrapper>
      {isModalTeleconsultingOpen && (
        <Modal onClickAway={() => setModalTeleconsultingOpen(false)}>
          <ModalTeleconsulting
            title={getText('teleconsulting_title')}
            subtitle={getText('teleconsulting_subtitle')}
            teleconsultationPrice={teleconsultationPrice}
            onClose={() => setModalTeleconsultingOpen(false)}
          />
        </Modal>
      )}
      {isModalZoneInterventionOpen && (
        <Modal onClickAway={() => setModalZoneInterventionOpen(false)}>
          <ModalZoneIntervention
            teleconsultationPrice={teleconsultationPrice}
            onClose={() => setModalZoneInterventionOpen(false)}
          />
        </Modal>
      )}
    </div>
  )
}

export default RightContent
