import { ReactNode, ButtonHTMLAttributes } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import theme from '../../../theme/theme'

export enum ButtonShape {
  default = 'default',
  outline = 'outline',
  ghost = 'ghost',
}

export enum ButtonColor {
  primary = 'primary',
  white = 'white',
}

export enum ButtonSize {
  sm = 'sm',
  md = 'md',
}

type ButtonStyledProps = Pick<ButtonProps, 'color' | 'shape' | 'size'> & {
  hasIcon: boolean
}

type ButtonColorVariables = {
  default: string
  hover: string
  pressed: string
  disabled: string
  text: string
  border: string
  textDisabled: string
}

const colorsVariables = (colors: ButtonColorVariables) => css`
  --colorDefault: ${colors.default};
  --colorHover: ${colors.hover};
  --colorPressed: ${colors.pressed};
  --colorDisabled: ${colors.disabled};
  --colorText: ${colors.text};
  --colorBorder: ${colors.border};
  --colorTextDisabled: ${colors.textDisabled};
`

const styleColors = {
  primary: colorsVariables({
    default: theme.colors.primary[500],
    disabled: theme.colors.grey[300],
    hover: theme.colors.secondary[500],
    pressed: theme.colors.primary[500],
    text: theme.colors.white[500],
    border: 'transparent',
    textDisabled: theme.colors.white[500],
  }),
  primaryOutline: colorsVariables({
    default: theme.colors.white[500],
    disabled: theme.colors.grey[100],
    hover: theme.colors.secondary[500],
    pressed: theme.colors.grey[400],
    text: theme.colors.primary[500],
    border: theme.colors.primary[500],
    textDisabled: theme.colors.primary[500],
  }),
  white: colorsVariables({
    default: theme.colors.white[500],
    disabled: theme.colors.grey[100],
    hover: theme.colors.grey[200],
    pressed: theme.colors.grey[200],
    text: theme.colors.black[500],
    border: 'transparent',
    textDisabled: theme.colors.black[500],
  }),
  whiteOutline: colorsVariables({
    default: theme.colors.white[500],
    disabled: theme.colors.grey[100],
    hover: theme.colors.grey[400],
    pressed: theme.colors.grey[200],
    text: theme.colors.black[500],
    border: theme.colors.grey[300],
    textDisabled: theme.colors.black[500],
  }),
}

const styleShapes = {
  default: css`
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: var(--colorDefault);
    color: var(--colorText);

    @media (hover: hover) {
      &:hover {
        background-color: var(--colorHover);
      }
    }

    &:active {
      background-color: var(--colorPressed);
    }
    &:disabled {
      background-color: var(--colorDisabled);
      color: var(--colorTextDisabled);
      cursor: not-allowed;
    }
  `,

  outline: css`
    background-color: var(--colorDefault);
    border: 1px solid var(--colorBorder);
    color: var(--colorText);
    border-radius: 5px;

    @media (hover: hover) {
      &:hover {
        border-color: var(--colorHover);
      }
    }

    &:active {
      border-color: var(--colorPressed);
    }
    &:disabled {
      border-color: var(--colorDisabled);
      color: var(--colorDisabled);
      cursor: not-allowed;
    }
  `,

  ghost: css`
    background-color: transparent;
    color: var(--colorDefault);
    border: 0;
    border-radius: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    width: auto;

    @media (hover: hover) {
      &:hover {
        color: var(--colorHover);

        &:before {
          background-color: var(--colorHover);
        }
      }
    }

    &:active {
      color: var(--colorPressed);
    }
    &:disabled {
      color: var(--colorDisabled);
      cursor: not-allowed;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      width: 100%;
      background-color: var(--colorDefault);
    }
  `,
}

const styleSize = {
  sm: css`
    padding: ${theme.spacings[6]};
  `,

  md: css`
    padding: ${theme.spacings[8]};
  `,
}

const shapeMapColor = {
  outline: {
    white: styleColors['whiteOutline'],
    primary: styleColors['primaryOutline'],
  },
  default: {
    white: styleColors['white'],
    primary: styleColors['primary'],
  },
  ghost: {
    white: styleColors['white'],
    primary: styleColors['primary'],
  },
}

const CommonStyles = (props: ButtonStyledProps) => css`
  position: relative;
  width: 100%;

  display: flex;
  justify-content: ${props.hasIcon ? 'space-between' : 'center'};
  align-items: center;

  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: ${props.hasIcon ? 'left' : 'center'};

  outline: none;
  cursor: pointer;
  transition: color 0.15s ease-out, background-color 0.15s ease-out;

  > svg {
    width: 1rem;
    margin-left: 1rem;
  }

  ${props.size && styleSize[props.size]}
  ${props.shape && props.color && shapeMapColor[props.shape][props.color]}
  ${props.shape && styleShapes[props.shape]}
`

const StyledA = styled.a<ButtonStyledProps>`
  ${(props) => CommonStyles(props)}
  text-decoration: none;
  width: auto;
`
const StyledButton = styled.button<ButtonStyledProps>`
  ${(props) => CommonStyles(props)}
`
const StyledLink = styled(Link)<ButtonStyledProps>`
  ${(props) => CommonStyles(props)}
  text-decoration: none;
  width: auto;
`

export interface ButtonWithIconProps {
  color?: keyof typeof ButtonColor
  shape?: keyof typeof ButtonShape
  size?: keyof typeof ButtonSize
  icon?: ReactNode
}

export type ButtonProps = ButtonWithIconProps &
  (ButtonHTMLAttributes<HTMLButtonElement> | LinkProps) & {
    type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  }

const Button = ({
  color = 'primary',
  shape = 'default',
  size = 'sm',
  icon,
  children,
  ...rest
}: ButtonProps) => {
  if ('href' in rest) {
    return (
      <StyledA
        shape={shape}
        color={color}
        hasIcon={!!icon}
        size={size}
        {...rest}
      >
        {children}
        {icon}
      </StyledA>
    )
  }

  if ('to' in rest) {
    return (
      <StyledLink
        shape={shape}
        color={color}
        hasIcon={!!icon}
        size={size}
        {...rest}
      >
        {children}
        {icon}
      </StyledLink>
    )
  }

  return (
    <StyledButton
      type={rest.type || 'button'}
      shape={shape}
      color={color}
      hasIcon={!!icon}
      size={size}
      {...rest}
    >
      {children}
      {icon}
    </StyledButton>
  )
}

export default styled(Button)<ButtonProps>``
