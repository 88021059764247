import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacings[6]};
  width: 100%;

  ${({ theme }) =>
    Object.values(theme.breakpoints).map(
      (breakpoint) => css`
        @media (min-width: ${breakpoint}) {
          max-width: ${breakpoint};
        }
      `
    )}
`

type ContainerProps = {
  children: ReactNode
}

const Container = ({ children }: ContainerProps) => {
  return <Wrapper>{children}</Wrapper>
}

export default Container
