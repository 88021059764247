import { isAfter } from 'date-fns'
import { useEffect, useState, MutableRefObject, useCallback } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components/macro'

import { ReactComponent as ArrowRight } from '../../assets/icons/arrowRight.svg'
import { useAppointmentForm } from '../../contexts/AppointmentForm'
import { getTeleconsultationPrice } from '../../queries/getTeleconsultationPrice'
import Routes from '../../router/Routes'
import Button from '../atoms/button/Button'
import Checkbox from '../atoms/checkbox/Checkbox'
import Modal from '../atoms/modal/Modal'
import { Bold, SelectedDate } from './AppointmentCalendarIntervention'
import Availabilities from './Availabilities'
import ModalCalendar from './modals/ModalCalendar'

export const Wrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacings[12]} ${theme.spacings[6]}`};

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    padding: ${({ theme }) => `${theme.spacings[36]} ${theme.spacings[6]}`};
    max-width: 424px;
    margin: 0 auto;
  }
`

const CTAWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings[12]};
  width: 100%;
`

export type TeleconsultingCalendarProps = {
  parentRef: MutableRefObject<HTMLDivElement | null>
}

const TeleconsultingCalendar = ({ parentRef }: TeleconsultingCalendarProps) => {
  const history = useHistory()
  const { appointmentFormData, saveData } = useAppointmentForm()

  const [isChecked, setChecked] = useState(true)
  const [isModalCalendarOpen, setModalCalendarOpen] = useState(false)
  const [dates, setDates] = useState<SelectedDate[]>([])
  const [teleconsultationPrice, setTeleconsultationPrice] = useState<
    number | null
  >(null)

  useEffect(() => {
    if (typeof appointmentFormData?.teleconsultationCheck === 'boolean') {
      setChecked(appointmentFormData?.teleconsultationCheck)
    }
  }, [appointmentFormData])

  useEffect(() => {
    if (!appointmentFormData?.teleconsultationDates) return

    const dates = appointmentFormData?.teleconsultationDates.filter((date) =>
      isAfter(new Date(date._rawDate), new Date())
    )

    setDates(dates)
  }, [appointmentFormData])

  useEffect(() => {
    getTeleconsultationPrice()
      .then((price) => setTeleconsultationPrice(price))
      .catch((error) => console.error(error))
  }, [])

  useEffect(() => {
    if (dates.length <= 0) return

    parentRef.current?.parentElement?.scrollTo(
      0,
      parentRef.current.scrollHeight
    )
  }, [dates, parentRef])

  const handleClickCTA = useCallback(
    (withData: boolean) => {
      setDates(withData ? dates : [])
      saveData?.({
        teleconsultationDates: withData ? dates : undefined,
        teleconsultationCheck: isChecked,
      })
      history.push(Routes.AppointmentValidation)
    },
    [saveData, history, dates, isChecked]
  )

  return (
    <Wrapper>
      <Checkbox onChange={() => setChecked(!isChecked)} checked={isChecked}>
        Ça m'interesse
      </Checkbox>

      {isChecked && teleconsultationPrice && (
        <Availabilities
          description={
            <>
              Renseignez vos disponibilités{' '}
              <Bold>(2 créneaux minimum sur des jours différents)</Bold> et nous
              vous confirmerons votre rendez-vous par mail
            </>
          }
          dates={dates}
          setDates={setDates}
          setModalCalendarOpen={setModalCalendarOpen}
          price={teleconsultationPrice}
          teleconsulting
          onClick={() => handleClickCTA(true)}
        />
      )}

      {!isChecked && (
        <CTAWrapper>
          <Button onClick={() => handleClickCTA(false)} icon={<ArrowRight />}>
            Continuer
          </Button>
        </CTAWrapper>
      )}

      {isModalCalendarOpen && teleconsultationPrice && (
        <Modal onClickAway={() => setModalCalendarOpen(false)} size="small">
          <ModalCalendar
            onClose={() => setModalCalendarOpen(false)}
            setDates={setDates}
            dates={dates}
            teleconsulting
            price={teleconsultationPrice}
          />
        </Modal>
      )}
    </Wrapper>
  )
}

export default TeleconsultingCalendar
