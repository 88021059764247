import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import styled from 'styled-components/macro'

import { ReactComponent as ArrowIcon } from '../assets/icons/arrowRight.svg'
import Button from '../components/atoms/button/Button'
import Breadcrumb from '../components/molecules/breadcrumb/Breadcrumb'
import Header from '../components/molecules/header/Header'
import Menu, { MenuItemValue } from '../components/molecules/menu/Menu'
import LocalStorageKeys from '../localStorageKeys'
import { getDescribeLeakChoices } from '../queries/getDescribeLeakChoices'
import Routes from '../router/Routes'

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 48px;
  height: 100%;
`

const Text = styled.p`
  color: ${({ theme }) => theme.colors.white[400]};
  font-weight: 500;
  font-size: 30px;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    font-size: 48px;
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 32px;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    margin-top: 72px;
    justify-content: unset;
  }
`
const SentencesWrapper = styled.div`
  width: 100%;
`

const SentenceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  > * {
    margin-right: 0.5rem;
  }
`

const SubmitButton = styled(Button)`
  margin-top: 64px;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    width: 265px;
  }
`

type FormData = {
  problem: MenuItemValue
  nature: MenuItemValue
  location: MenuItemValue
}

const DescribeLeak = () => {
  const history = useHistory()
  const { watch, setValue, handleSubmit } = useForm<FormData>()
  const [problems, setProblems] = useState<MenuItemValue[]>([])
  const [natures, setNatures] = useState<MenuItemValue[]>([])
  const [locations, setLocations] = useState<MenuItemValue[]>([])

  useEffect(() => {
    getDescribeLeakChoices("J'ai un problème", setProblems)
    getDescribeLeakChoices('Je vois', setNatures)
    getDescribeLeakChoices('Qui se situe', setLocations)
  }, [])

  useEffect(() => {
    const describeLeakForm = localStorage.getItem(
      LocalStorageKeys.describeLeakForm
    )

    if (describeLeakForm) {
      const formData = JSON.parse(describeLeakForm)
      setValue('problem', formData.problem)
      setValue('nature', formData.nature)
      setValue('location', formData.location)
    }
  }, [setValue])

  const onSubmit = handleSubmit((data) => {
    localStorage.setItem(
      LocalStorageKeys.describeLeakForm,
      JSON.stringify(data)
    )
    history.push(Routes.ProposalService)
  })

  return (
    <>
      <PageWrapper>
        <Header
          onClick={() =>
            (window.location.href = process.env.REACT_APP_CDF_WEBSITE_URL || '')
          }
        >
          <Breadcrumb variant="light">
            <Breadcrumb.Item selected>1. Décrire ma fuite</Breadcrumb.Item>
            <Breadcrumb.Item>2. Votre besoin</Breadcrumb.Item>
          </Breadcrumb>
        </Header>

        <StyledForm onSubmit={onSubmit}>
          <SentencesWrapper>
            <SentenceWrapper>
              <Text>Je constate</Text>
              <Menu
                placeholder="mon compteur d'eau qui tourne"
                value={watch('nature')}
                onClick={(e) => setValue('nature', e.value)}
              >
                {natures.map((nature) => (
                  <Menu.Item key={nature.value} value={nature}>
                    {nature.label}
                  </Menu.Item>
                ))}
              </Menu>
            </SentenceWrapper>

            {watch('nature') && (
              <SentenceWrapper>
                <Text>Qui se situe</Text>
                <Menu
                  placeholder="dans l'appartement"
                  value={watch('location')}
                  onClick={(e) => setValue('location', e.value)}
                >
                  {locations.map((location) => (
                    <Menu.Item key={location.value} value={location}>
                      {location.label}
                    </Menu.Item>
                  ))}
                </Menu>
              </SentenceWrapper>
            )}

            {watch('location') && (
              <SentenceWrapper>
                <Text>Donc j'ai un problème</Text>
                <Menu
                  placeholder="de surconsommation"
                  value={watch('problem')}
                  onClick={(e) => setValue('problem', e.value)}
                >
                  {problems.map((problem) => (
                    <Menu.Item key={problem.value} value={problem}>
                      {problem.label}
                    </Menu.Item>
                  ))}
                </Menu>
              </SentenceWrapper>
            )}
          </SentencesWrapper>

          {watch('problem') && (
            <SubmitButton type="submit" icon={<ArrowIcon />}>
              Confirmer
            </SubmitButton>
          )}
        </StyledForm>
      </PageWrapper>
    </>
  )
}

export default DescribeLeak
