export const formatPrice = (price: number) => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(price)
}

export const getMajoratedPrice = (price: number, coef: number) => {
  return formatPrice(price * coef)
}
