import { airtable } from '../airtable'
import { CoefficientMajoration } from '../sdk/coefficient'

export async function getCoefficientMajoration() {
  const records = await airtable<CoefficientMajoration>(
    'Coefficient majoration'
  )
    .select({
      view: 'Grid view',
    })
    .all()

  return records?.[0].get('coefficient')
}
