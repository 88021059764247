const LocalStorageKeys = {
  postalCode: 'postalCode',
  city: 'city',
  describeLeakForm: 'describeLeakForm',
  appointmentFormData: 'appointmentFormData',
  priceParking: 'priceParking',
  prestationPrice: 'prestationPrice',
  answer: 'answer',
  totalPrice: 'totalPrice',
}

export default LocalStorageKeys
