import { cloneElement, ReactElement } from 'react'
import styled, { css } from 'styled-components/macro'

import BreadcrumbItem, {
  BreadcrumbItemProps,
  BreadcrumbItemVariant,
} from './BreadcrumbItem'

const StyledGreyVariant = css`
  padding: ${({ theme }) => `${theme.spacings[4]} 0`};
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;

    & > * + * {
      margin-top: ${({ theme }) => theme.spacings[1]};
    }
  }
`

const StyledVariant = css`
  & > * + * {
    margin-left: ${({ theme }) => theme.spacings[4]};
  }
`

const styledVariants = {
  [BreadcrumbItemVariant.light]: StyledVariant,
  [BreadcrumbItemVariant.dark]: StyledVariant,
  [BreadcrumbItemVariant.grey]: StyledGreyVariant,
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacings[3]} 0`};
  font-size: 14px;
  line-height: 1.5;

  ${({ variant }) => variant && styledVariants[variant]}
`

type WrapperProps = Pick<BreadcrumbProps, 'variant'>

export type BreadcrumbProps = {
  children: ReactElement<BreadcrumbItemProps>[]
  variant?: BreadcrumbItemProps['variant']
}

const Breadcrumb = ({ children, variant = 'grey' }: BreadcrumbProps) => {
  return (
    <Wrapper variant={variant}>
      {children.map((child, index) =>
        cloneElement(child, {
          key: index,
          variant,
        })
      )}
    </Wrapper>
  )
}

Breadcrumb.Item = BreadcrumbItem
export default Breadcrumb
