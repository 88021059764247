import { ReactElement } from 'react'
import styled from 'styled-components/macro'

import { useWindowSize } from '../../../hooks/useWindowSize'
import { breakpointsValues } from '../../../theme/breakpoints'
import Carousel from '../carousel/Carousel'
import DualCardItem, { DualCardItemProps } from './DualCardItem'

const TABLET_BREAKPOINT = 'tablet'

const SliderWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacings[18]} ${theme.spacings[6]}`};

  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[TABLET_BREAKPOINT]}) {
    max-width: 872px;
    padding: ${({ theme }) =>
      `${theme.spacings[25]} ${theme.spacings[6]} ${theme.spacings[36]}`};
  }
`

const SliderBackground = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey[100]};
`

const SliderTitle = styled.p`
  font-weight: 500;
  font-size: 19px;
  margin-bottom: ${({ theme }) => theme.spacings[12]};
`

const CardWrapper = styled.div`
  display: flex;

  & > * + * {
    margin-left: ${({ theme }) => theme.spacings[6]};
  }
`

export type DualCardsProps = {
  title: string
  children: ReactElement<DualCardItemProps>[]
}

const DualCards = ({ title, children }: DualCardsProps) => {
  const size = useWindowSize()

  return (
    <SliderBackground>
      <SliderWrapper>
        <SliderTitle>{title}</SliderTitle>
        {size.width && size.width > breakpointsValues[TABLET_BREAKPOINT] ? (
          <CardWrapper>{children}</CardWrapper>
        ) : (
          <Carousel>
            {children.map((child, index) => (
              <Carousel.Item key={index}>{child}</Carousel.Item>
            ))}
          </Carousel>
        )}
      </SliderWrapper>
    </SliderBackground>
  )
}

DualCards.Item = DualCardItem

export default DualCards
