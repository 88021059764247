import { airtable } from '../airtable'
import { HolidayDate } from '../components/organisms/modals/ModalCalendar'
import { Holiday } from '../sdk/Holiday'

export const getHolidays = (callback: (val: HolidayDate[]) => void) => {
  airtable<Holiday>('Dates jours fériés')
    .select({
      view: 'Grid view',
    })
    .eachPage(
      function page(records, fetchNextPage) {
        const dates = records.map((r) => {
          return {
            date: r.get('Name'),
          }
        })

        const filteredDates = dates.filter((record) => !!record.date)

        callback(filteredDates)
        fetchNextPage()
      },
      function done(err) {
        if (err) {
          console.error(err)
          return
        }
      }
    )
}
