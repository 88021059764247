import styled from 'styled-components/macro'

import Routes from '../../../router/Routes'
import { getMajoratedPrice } from '../../../utils/price'
import Button from '../../atoms/button/Button'
import Card from '../../atoms/card/Card'
import { SelectedDate } from '../AppointmentCalendarIntervention'
import {
  CardHeading,
  Section,
  SectionTitle,
  TitleContent,
} from './ValidationStyled'

const RedText = styled.span`
  color: ${({ theme }) => theme.colors.primary[500]};
`

type TeleconsultationRecapProps = {
  teleconsultationDates: SelectedDate[]
  teleconsultationPrice: number
  coefficientMajoration: number
}

const TeleconsultationRecap = ({
  teleconsultationDates,
  teleconsultationPrice,
  coefficientMajoration,
}: TeleconsultationRecapProps) => {
  return (
    <Section>
      <SectionTitle>
        <TitleContent>Téléconsultation</TitleContent>
        <Button to={Routes.AppointmentCalendar} shape="ghost">
          Modifier
        </Button>
      </SectionTitle>
      <Card withBorder>
        <CardHeading>Créneaux</CardHeading>
        {teleconsultationDates.map((teleconsultation, i) => (
          <p key={i}>
            {teleconsultation.date} - {teleconsultation.hours}
            {teleconsultation.majoration ? (
              <RedText>{` - Majoration +
            ${getMajoratedPrice(
              teleconsultationPrice,
              coefficientMajoration
            )}`}</RedText>
            ) : (
              ''
            )}
          </p>
        ))}
      </Card>
    </Section>
  )
}

export default TeleconsultationRecap
