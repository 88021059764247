import { airtable } from '../airtable'

export async function getPrices(postalCode: string) {
  const records = await airtable('Codes postaux')
    .select({
      view: 'Grid view',
      filterByFormula: `{postal_code} = "${postalCode}"`,
    })
    .all()

  return records?.[0]?.fields
}
