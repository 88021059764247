import { useEffect, useState } from 'react'
import { Switch, useHistory, useLocation, Route } from 'react-router'
import styled from 'styled-components/macro'

import Container from '../components/atoms/Container'
import AsideNavigation from '../components/molecules/asideNavigation/AsideNavigation'
import Breadcrumb from '../components/molecules/breadcrumb/Breadcrumb'
import { AppointmentFormProvider } from '../contexts/AppointmentForm'
import LocalStorageKeys from '../localStorageKeys'
import AppointmentCalendar from '../pages/appointment/AppointmentCalendar'
import AppointmentContact from '../pages/appointment/AppointmentContact'
import AppointmentIntervention from '../pages/appointment/AppointmentIntervention'
import AppointmentPayment from '../pages/appointment/AppointmentPayment'
import AppointmentSuccess from '../pages/appointment/AppointmentSuccess'
import AppointmentValidation from '../pages/appointment/AppointmentValidation'
import Routes from '../router/Routes'
import { Answer } from '../sdk/Answer'

const Wrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    display: flex;
    justify-content: space-between;
  }
`

const AppointmentLayout = () => {
  const history = useHistory()
  const location = useLocation()
  const [answer, setAnswer] = useState<Answer>()

  useEffect(() => {
    const answerFromStorage = localStorage.getItem(LocalStorageKeys.answer)
    if (answerFromStorage) {
      setAnswer(JSON.parse(answerFromStorage))
    }
  }, [])

  return (
    <Container>
      <Wrapper>
        <AsideNavigation
          title="Prendre rendez-vous"
          subtitle={answer?.title || ''}
          onClose={() => history.push(Routes.ProposalService)}
        >
          <Breadcrumb variant="grey">
            <Breadcrumb.Item
              to={
                location.pathname !== Routes.AppointmentSuccess
                  ? Routes.AppointmentContact
                  : undefined
              }
              selected={location.pathname === Routes.AppointmentContact}
            >
              1. Contact
            </Breadcrumb.Item>
            <Breadcrumb.Item
              to={
                location.pathname !== Routes.AppointmentContact &&
                location.pathname !== Routes.AppointmentSuccess
                  ? Routes.AppointmentIntervention
                  : undefined
              }
              selected={
                location.pathname === Routes.AppointmentIntervention ||
                location.pathname === Routes.AppointmentCalendar
              }
            >
              2. Intervention
            </Breadcrumb.Item>
            <Breadcrumb.Item
              to={
                location.pathname === Routes.AppointmentPayment
                  ? Routes.AppointmentIntervention
                  : undefined
              }
              selected={location.pathname === Routes.AppointmentValidation}
            >
              3. Validation
            </Breadcrumb.Item>
            <Breadcrumb.Item
              selected={location.pathname === Routes.AppointmentPayment}
            >
              4. Paiement
            </Breadcrumb.Item>
          </Breadcrumb>
        </AsideNavigation>

        <AppointmentFormProvider>
          <Switch>
            <Route
              path={Routes.AppointmentContact}
              component={AppointmentContact}
            />
            <Route
              path={Routes.AppointmentIntervention}
              component={AppointmentIntervention}
            />
            <Route
              path={Routes.AppointmentCalendar}
              component={AppointmentCalendar}
            />
            <Route
              path={Routes.AppointmentValidation}
              component={AppointmentValidation}
            />
            <Route
              path={Routes.AppointmentPayment}
              component={AppointmentPayment}
            />
            <Route
              path={Routes.AppointmentSuccess}
              component={AppointmentSuccess}
            />
          </Switch>
        </AppointmentFormProvider>
      </Wrapper>
    </Container>
  )
}

export default AppointmentLayout
