import { addDays, eachDayOfInterval } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'

import { isMonthChangeThisWeek } from '../utils/date'

const BASE_END = 5

export function useCalendar() {
  const [nextDays, setNextDays] = useState<Date[]>([])
  const [start, setStart] = useState(addDays(new Date(), 1))
  const [end, setEnd] = useState(BASE_END)

  const [history, setHistory] = useState<Date[][] | null>(null)

  const getNextDays = useCallback(() => {
    const next = eachDayOfInterval({
      start,
      end: addDays(start, end),
    })

    const firstDayOfMonth = isMonthChangeThisWeek(next)

    if (firstDayOfMonth) {
      const lastDayOfWeek = next.indexOf(firstDayOfMonth)

      const newNext = eachDayOfInterval({
        start,
        end: addDays(start, lastDayOfWeek - 1),
      })

      setHistory((prevState) =>
        prevState ? [...prevState, newNext] : [newNext]
      )

      return newNext
    } else {
      setHistory((prevState) => (prevState ? [...prevState, next] : [next]))
      return next
    }
  }, [start, end])

  const getNextWeek = useCallback(
    (currentIndex: number) => {
      const newStart = addDays(new Date(nextDays[nextDays.length - 1]), 1)
      const nextEnd = currentIndex === 0 ? BASE_END : 4

      setStart(newStart)

      const nextWeek = eachDayOfInterval({
        start: newStart,
        end: addDays(newStart, nextEnd),
      })

      const firstDayOfMonth = isMonthChangeThisWeek(nextWeek)

      if (firstDayOfMonth) {
        const lastDayOfWeek = nextWeek.indexOf(firstDayOfMonth)

        setEnd(lastDayOfWeek - 1)
        return
      }

      setEnd(nextEnd)
    },
    [nextDays]
  )

  useEffect(() => {
    setNextDays(getNextDays())
  }, [getNextDays])

  return { history, getNextWeek }
}
