import { useEffect, useState } from 'react'

import { ReactComponent as Searchicon } from '../../../assets/icons/search.svg'
import { getTexts } from '../../../queries/getTexts'
import { Text } from '../../../sdk/Text'
import Card from '../../atoms/card/Card'
import Modal from '../../atoms/modal/Modal'
import ModalAssurance from '../modals/ModalAssurance'
import ModalTeleconsulting from '../modals/ModalTeleconsulting'
import {
  CardInfo,
  CardTitle,
  CardWrapper,
  Price,
  StyledCard,
  SubPrice,
} from './ProposalServiceStyled'

type RightContentIZAnswerProps = {
  price: string
  teleconsultationPrice: string
}

const RightContentIZAnswer = ({
  price,
  teleconsultationPrice,
}: RightContentIZAnswerProps) => {
  const [isModalAssuranceOpen, setModalAssuranceOpen] = useState(false)
  const [isModalTeleconsultingOpen, setModalTeleconsultingOpen] =
    useState(false)
  const [texts, setTexts] = useState<Text[]>([])

  useEffect(() => {
    getTexts(
      [
        'insurance_title',
        'insurance_subtitle',
        'insurance_card1_title',
        'insurance_card1_content',
        'insurance_card2_title',
        'insurance_card2_content',
        'teleconsulting_title',
        'teleconsulting_subtitle',
      ],
      setTexts
    )
  }, [])

  const getText = (key: string) =>
    texts.find((t) => t.key === key)?.content || ''

  return (
    <div>
      <Price>
        À partir de {price}
        <SubPrice>Règlement après l’intervention</SubPrice>
      </Price>
      <CardWrapper>
        <Card color="white">
          <StyledCard>
            <CardTitle>
              Cette intervention peut être prise en charge par votre assurance
            </CardTitle>
            <Searchicon onClick={() => setModalAssuranceOpen(true)} />
          </StyledCard>
        </Card>

        <Card color="white">
          <StyledCard>
            <CardTitle>
              Profitez de la téléconsultation à {teleconsultationPrice}
            </CardTitle>
            <Searchicon onClick={() => setModalTeleconsultingOpen(true)} />
          </StyledCard>
          <CardInfo>
            Un expert en visioconférence en moins d’une heure, remboursé en cas
            d’intervention
          </CardInfo>
        </Card>
      </CardWrapper>
      {isModalAssuranceOpen && (
        <Modal onClickAway={() => setModalAssuranceOpen(false)}>
          <ModalAssurance
            title={getText('insurance_title')}
            subtitle={getText('insurance_subtitle')}
            card1Title={getText('insurance_card1_title')}
            card1Content={getText('insurance_card1_content')}
            card2Title={getText('insurance_card2_title')}
            card2Content={getText('insurance_card2_content')}
            onClose={() => setModalAssuranceOpen(false)}
          />
        </Modal>
      )}
      {isModalTeleconsultingOpen && (
        <Modal onClickAway={() => setModalTeleconsultingOpen(false)}>
          <ModalTeleconsulting
            title={getText('teleconsulting_title')}
            subtitle={getText('teleconsulting_subtitle')}
            teleconsultationPrice={teleconsultationPrice}
            onClose={() => setModalTeleconsultingOpen(false)}
          />
        </Modal>
      )}
    </div>
  )
}

export default RightContentIZAnswer
