import { ReactNode } from 'react'
import styled from 'styled-components/macro'

import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/cross.svg'
import Card from '../../atoms/card/Card'

const CardInner = styled.div`
  padding: ${({ theme }) => theme.spacings[1]};

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    padding: ${({ theme }) =>
      `${theme.spacings[1]} ${theme.spacings[1]} ${theme.spacings[12]}`};
  }
`

type CardInnerTopProps = {
  color: 'black' | 'primary'
}

const CardInnerTop = styled.div<CardInnerTopProps>`
  padding-bottom: ${({ theme }) => theme.spacings[8]};
  color: ${({ theme, color }) => theme.colors[color][500]};
  font-size: 24px;
  font-weight: 500;
`

const CardInnerIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: currentColor;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacings[12]};

  & svg {
    width: 17px;
    color: ${({ theme }) => theme.colors.white[500]};
  }
`

export type DualCardItemProps = {
  title: string
  color?: CardInnerTopProps['color']
  children: ReactNode
}

const DualCardItem = ({
  title,
  color = 'black',
  children,
}: DualCardItemProps) => {
  return (
    <Card color="white">
      <CardInner>
        <CardInnerTop color={color}>
          <CardInnerIcon>
            {color === 'black' ? <CheckIcon /> : <CloseIcon />}
          </CardInnerIcon>
          {title}
        </CardInnerTop>
        {children}
      </CardInner>
    </Card>
  )
}

export default DualCardItem
