import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { ReactComponent as Searchicon } from '../../../assets/icons/search.svg'
import FranceImg from '../../../assets/images/france.png'
import TeleconsultingImg from '../../../assets/images/teleconsultation.png'
import { getTexts } from '../../../queries/getTexts'
import { Text } from '../../../sdk/Text'
import Card from '../../atoms/card/Card'
import Modal from '../../atoms/modal/Modal'
import Title from '../../atoms/title/Title'
import Header from '../../molecules/header/Header'
import { CardTitle, StyledCard } from '../ProposalService/ProposalServiceStyled'
import {
  BoldText,
  HeadingWrapper,
  InformationWrapper,
  ModalPageWrapper,
} from './ModalAssurance'
import ModalTeleconsulting from './ModalTeleconsulting'

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    flex-direction: row;

    & > * {
      width: 50%;
    }
  }
`

const StyledP = styled.p`
  color: ${({ theme }) => theme.colors.black[400]};
`

const BigCard = styled.div<{ colored?: boolean }>`
  padding: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    padding: 5rem;
  }

  ${({ colored, theme }) =>
    colored &&
    css`
      background-color: ${theme.colors.grey[100]};
    `}

  & > * + * {
    margin-top: 2rem;
  }
`

const CardBigTitle = styled.h2`
  font-size: 24px;
  line-height: 150%;
  font-weight: 500;
`

const ImgWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    flex: 1;
    width: 50%;
    height: auto;
    padding-bottom: 0;
  }
`

const Img = styled.img`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    position: relative;
    top: unset;
    left: unset;
    transform: translate(0, 0);
  }
`

export type ModalZoneInterventionProps = {
  teleconsultationPrice: string
  onClose: () => void
}

const ModalZoneIntervention = ({
  teleconsultationPrice,
  onClose,
}: ModalZoneInterventionProps) => {
  const [isModalTeleconsultingOpen, setModalTeleconsultingOpen] =
    useState(false)
  const [texts, setTexts] = useState<Text[]>([])

  useEffect(() => {
    getTexts(['teleconsulting_title', 'teleconsulting_subtitle'], setTexts)
  }, [])

  const getText = (key: string) =>
    texts.find((t) => t.key === key)?.content || ''

  return (
    <>
      <ModalPageWrapper>
        <InformationWrapper>
          <Header variant="dark" onClick={onClose}>
            <BoldText>Zone d'intervention</BoldText>
          </Header>

          <HeadingWrapper>
            <Title>
              Nous intervenon en Provence Alpes Côte d'Azur et bientôt dans
              toute la France
            </Title>
          </HeadingWrapper>
        </InformationWrapper>

        <FlexContainer>
          <ImgWrapper>
            <Img src={FranceImg} alt="france" />
          </ImgWrapper>
          <BigCard>
            <StyledP>
              Notre flotte d’experts de la fuite sont à votre service partout en
              France par téléconsultation. Ils se déplacent dans les
              départements du Var, Alpes Maritimes, depuis Le Muy (83), où est
              installé notre siège social, ainsi que dans les Bouches-du-Rhône,
              le Vaucluse et l’est du Gard, depuis notre agence de Cavaillon
              (84). Et bientôt, dans toute la France !
            </StyledP>
          </BigCard>
        </FlexContainer>
        <FlexContainer>
          <BigCard colored>
            <CardBigTitle>
              Même si nous n’intervenons pas encore dans votre zone, nous
              pouvons vous aider !
            </CardBigTitle>
            <StyledP>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim
              ad minim veniam, quis nostrud exercitation ullamco.
            </StyledP>
            <Card color="white">
              <StyledCard>
                <CardTitle>En savoir plus sur la téléconsultation</CardTitle>
                <Searchicon onClick={() => setModalTeleconsultingOpen(true)} />
              </StyledCard>
            </Card>
          </BigCard>
          <ImgWrapper>
            <Img src={TeleconsultingImg} alt="teleconsulting" />
          </ImgWrapper>
        </FlexContainer>
      </ModalPageWrapper>
      {isModalTeleconsultingOpen && (
        <Modal onClickAway={() => setModalTeleconsultingOpen(false)}>
          <ModalTeleconsulting
            title={getText('teleconsulting_title')}
            subtitle={getText('teleconsulting_subtitle')}
            teleconsultationPrice={teleconsultationPrice}
            onClose={() => setModalTeleconsultingOpen(false)}
          />
        </Modal>
      )}
    </>
  )
}

export default ModalZoneIntervention
