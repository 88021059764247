import { ReactNode } from 'react'
import styled from 'styled-components/macro'

const TABLET_BREAKPOINT = 'tablet'

const SlideTitle = styled.p`
  font-weight: 500;
  margin: ${({ theme }) => `${theme.spacings[8]} 0 ${theme.spacings[4]}`};

  @media (min-width: ${({ theme }) => theme.breakpoints[TABLET_BREAKPOINT]}) {
    margin: ${({ theme }) => `${theme.spacings[6]} 0 ${theme.spacings[2]}`};
  }
`

const SlideText = styled.p`
  max-width: 240px;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.white[400]};
`

type BenefitCardProps = {
  icon: ReactNode
  title: string
  children: ReactNode
}

const BenefitCard = ({ icon, title, children }: BenefitCardProps) => (
  <div>
    {icon}
    <SlideTitle>{title}</SlideTitle>
    <SlideText>{children}</SlideText>
  </div>
)

export default BenefitCard
