import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import theme from '../../../theme/theme'

export enum CardColor {
  white = 'white',
  grey = 'grey',
}

const cardColors = {
  [CardColor.white]: theme.colors.white[500],
  [CardColor.grey]: theme.colors.grey[100],
}

type CardWrapperProps = Pick<CardProps, 'color' | 'withBorder'>

const CardWrapper = styled.div<CardWrapperProps>`
  background-color: ${({ color }) => color && cardColors[color]};
  padding: 1.5rem;
  border-radius: 5px;

  ${({ withBorder, theme }) =>
    withBorder &&
    css`
      border: 1px solid ${theme.colors.grey[300]};
    `}
`

export type CardProps = {
  children: ReactNode
  color?: keyof typeof CardColor
  withBorder?: boolean
  className?: string
}

const Card = ({
  children,
  color = 'white',
  withBorder,
  className,
}: CardProps) => {
  return (
    <CardWrapper color={color} withBorder={withBorder} className={className}>
      {children}
    </CardWrapper>
  )
}

export default styled(Card)``
