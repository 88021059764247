import {
  ControlledMenu,
  MenuItem,
  MenuItemProps,
  ControlledMenuProps,
} from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import { ReactElement, useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { ReactComponent as ChevronDown } from '../../../assets/icons/chevron-down.svg'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { breakpointsValues } from '../../../theme/breakpoints'

const MOBILE_PADDING = '24px'
const MOBILE_BREAKPOINT = 'mobile-lg'

const StyledMenuItem = styled(MenuItem)`
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
  padding: 1.5rem 4rem;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.grey[100]};
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.grey[200]};
    color: ${({ theme }) => theme.colors.black[500]};
  }
`

const StyledReactMenu = styled(ControlledMenu)`
  padding: 0;
  overflow: hidden;
  z-index: ${({ theme }) => theme.zIndex.modal};

  & > ${StyledMenuItem} + ${StyledMenuItem} {
    border-top: 1px solid rgba(13, 13, 15, 0.1);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[MOBILE_BREAKPOINT]}) {
    width: calc(100vw - ${MOBILE_PADDING} * 2);
  }
`

const Overlay = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.black[400]};
  z-index: ${({ theme }) => theme.zIndex.overlay};

  @media (min-width: ${({ theme }) => theme.breakpoints[MOBILE_BREAKPOINT]}) {
    display: none;
  }

  ${({ isOpen }) =>
    !isOpen &&
    css`
      display: none;
    `}
`

const ChevronDownIcon = styled(ChevronDown)`
  color: ${({ theme }) => theme.colors.white[500]};
  font-size: 1.5rem;
`

const MenuButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.colors.white[500]};
  background: transparent;
  color: ${({ theme }) => theme.colors.white[500]};
  cursor: pointer;
  font-weight: 500;
  font-size: 30px;
  line-height: 3rem;
  max-width: 100%;

  > ${ChevronDownIcon} {
    margin-left: ${({ theme }) => theme.spacings[1]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    font-size: 48px;
  }
`

const TextValue = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Placeholder = styled.span`
  color: ${({ theme }) => theme.colors.white[300]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export type MenuItemValue = {
  value: string
  label: string
}

export type MenuProps = Omit<ControlledMenuProps, 'children'> & {
  children: ReactElement<MenuItemProps>[]
  placeholder?: string
  value?: MenuItemValue
  className?: string
}

const Menu = ({
  children,
  value,
  onChange,
  placeholder,
  className,
  ...rest
}: MenuProps) => {
  const size = useWindowSize()
  const [isOpen, setIsOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const overlayRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <MenuButton
        type="button"
        ref={buttonRef}
        onClick={() => setIsOpen(true)}
        className={className}
      >
        {value ? (
          <TextValue>{value.label}</TextValue>
        ) : (
          <Placeholder>{placeholder}</Placeholder>
        )}{' '}
        <ChevronDownIcon />
      </MenuButton>

      <Overlay ref={overlayRef} isOpen={isOpen} />

      <StyledReactMenu
        {...rest}
        boundingBoxPadding={MOBILE_PADDING}
        anchorRef={
          size.width && size.width > breakpointsValues[MOBILE_BREAKPOINT]
            ? buttonRef
            : overlayRef
        }
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {children}
      </StyledReactMenu>
    </>
  )
}

Menu.Item = StyledMenuItem

export default styled(Menu)``
