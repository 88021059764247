const colors = {
  white: {
    300: 'rgba(255, 255, 255, 0.5)',
    400: 'rgba(255, 255, 255, 0.8)',
    500: '#FFFFFF',
  },
  black: {
    400: 'rgba(13, 13, 15, 0.6)',
    500: '#0D0D0F',
  },
  grey: {
    100: '#F3F3F3',
    200: '#E7E7E7',
    300: '#CFCFCF',
    400: '#565657',
    500: '#252527',
  },
  primary: {
    500: '#D62325',
  },
  secondary: {
    500: '#AF1D1F',
  },
  tertiary: {
    500: '#431315',
  },
  overlay: {
    500: 'rgba(13, 13, 15, 0.7)',
  },
  info: {
    500: '#D62325',
  },
  warning: {
    500: '#D62325',
  },
  success: {
    500: '#D62325',
  },
  danger: {
    500: '#D62325',
  },
  transparent: 'transparent',
}

export default colors
