import { createGlobalStyle } from 'styled-components/macro'

import breakpoints from './breakpoints'
import colors from './colors'
import FormulaCondensedBoldWoff from './fonts/FormulaCondensed/FormulaCondensed-Bold.woff'
import FormulaCondensedBoldWoff2 from './fonts/FormulaCondensed/FormulaCondensed-Bold.woff2'
import NeusaNextProMediumWoff from './fonts/NeusaNextPro/NeusaNextPro-Medium.woff'
import NeusaNextProMediumWoff2 from './fonts/NeusaNextPro/NeusaNextPro-Medium.woff2'
import NeusaNextProRegularWoff from './fonts/NeusaNextPro/NeusaNextPro-Regular.woff'
import NeusaNextProRegularWoff2 from './fonts/NeusaNextPro/NeusaNextPro-Regular.woff2'
import spacings from './spacings'
import typography from './typography'
import zIndex from './zIndex'

const theme = {
  breakpoints,
  spacings,
  colors,
  typography,
  zIndex,
}

export type Theme = typeof theme

export const GlobalStyle = createGlobalStyle`
  :root {
    --vh: 100%;
  }

  @font-face {
    font-family: 'Formula Condensed';
    src: url(${FormulaCondensedBoldWoff2})
        format('woff2'),
      url(${FormulaCondensedBoldWoff}) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Neusa Next Pro';
    src: url(${NeusaNextProRegularWoff2})
        format('woff2'),
      url(${NeusaNextProRegularWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Neusa Next Pro';
    src: url(${NeusaNextProMediumWoff2}) format('woff2'),
      url(${NeusaNextProMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: block;
  }

  html {
    font-size: 16px;
  }

  body {
    font-size: ${theme.spacings[4]};
    margin: 0;
    font-family: ${theme.typography.fontFamily};
    color: ${theme.colors.black[500]};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: none;
    overflow-x: hidden;
    min-height: unset;
  }

  #root {
    overflow-x: hidden;
    height: 100%;

    @media (min-width: ${theme.breakpoints['tablet']}) {
        overflow-x: unset;
    }
  }
`

export default theme
