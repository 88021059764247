import styled, { keyframes } from 'styled-components/macro'

const SpineAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 10px solid ${({ theme }) => theme.colors.grey[300]};
  border-top-color: ${({ theme }) => theme.colors.primary[500]};
  transform: rotate(0deg);
  animation: ${SpineAnimation} 1s linear 0s infinite;
`

export default styled(Spinner)``
