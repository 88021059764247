import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import styled from 'styled-components/macro'
import * as yup from 'yup'

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowRight.svg'
import Button from '../../components/atoms/button/Button'
import Input from '../../components/atoms/input/Input'
import Title from '../../components/atoms/title/Title'
import FormGroup from '../../components/molecules/formGroup/FormGroup'
import { useAppointmentForm } from '../../contexts/AppointmentForm'
import LocalStorageKeys from '../../localStorageKeys'
import Routes from '../../router/Routes'

const PageWrapper = styled.div`
  width: 100%;
  padding: 3rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    padding: ${({ theme }) =>
      `${theme.spacings[25]} 2rem ${theme.spacings[25]} 0`};
    margin-left: ${({ theme }) => theme.spacings[4]};
    max-width: 552px;

    ${Title} {
      max-width: 444px;
      font-size: clamp(40px, 7vw, 48px);
    }
  }
`

const StyledForm = styled.form`
  margin-top: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    margin-top: 4em;

    ${Button} {
      width: auto;
    }
  }
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${FormGroup} {
    width: 100%;
  }

  & > * + * {
    margin-left: 1.5rem;
  }
`

type AppointmentContactForm = {
  firstname: string
  lastname: string
  email: string
  phone: string
}

const schema = yup.object().shape({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup
    .string()
    .matches(
      /^((\+)33|0|0033)[1-9](\d{2}){4}$/,
      "Le numéro de téléphone renseigné n'est pas valide"
    )
    .required(),
})

const AppointmentContact = () => {
  const history = useHistory()
  const { appointmentFormData, saveData } = useAppointmentForm()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AppointmentContactForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstname: appointmentFormData?.firstname,
      lastname: appointmentFormData?.lastname,
      email: appointmentFormData?.email,
      phone: appointmentFormData?.phone,
    },
  })

  const onSubmit = handleSubmit((data) => {
    saveData?.(data)

    const prestationPrice = localStorage.getItem(
      LocalStorageKeys.prestationPrice
    )
    if (prestationPrice) {
      history.push(Routes.AppointmentIntervention)
    } else {
      history.push(Routes.AppointmentCalendar)
    }
  })

  return (
    <PageWrapper>
      <Title>Renseignez vos informations de contact</Title>

      <StyledForm onSubmit={onSubmit}>
        <FlexContainer>
          <FormGroup error={errors.firstname?.message}>
            <FormGroup.Label variant="small">Prénom *</FormGroup.Label>
            <Input
              {...register('firstname')}
              placeholder="Votre prénom"
              error={!!errors.firstname}
            />
          </FormGroup>
          <FormGroup error={errors.lastname?.message}>
            <FormGroup.Label variant="small">Nom *</FormGroup.Label>
            <Input
              {...register('lastname')}
              placeholder="Votre nom"
              error={!!errors.lastname}
            />
          </FormGroup>
        </FlexContainer>
        <FormGroup error={errors.email?.message}>
          <FormGroup.Label variant="small">Email *</FormGroup.Label>
          <Input
            {...register('email')}
            type="email"
            placeholder="Votre email"
            error={!!errors.email}
          />
        </FormGroup>
        <FormGroup error={errors.phone?.message}>
          <FormGroup.Label variant="small">Téléphone *</FormGroup.Label>
          <Input
            {...register('phone')}
            type="tel"
            placeholder="Votre n° de téléphone"
            error={!!errors.phone}
          />
        </FormGroup>
        <Button type="submit" icon={<ArrowIcon />}>
          Planifier mon intervention
        </Button>
      </StyledForm>
    </PageWrapper>
  )
}

export default AppointmentContact
