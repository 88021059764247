import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Stripe from 'stripe'
import styled from 'styled-components/macro'

import Title from '../../components/atoms/title/Title'
import CheckoutForm, {
  FormSkeleton,
} from '../../components/organisms/CheckoutForm'
import { useAppointmentForm } from '../../contexts/AppointmentForm'
import LocalStorageKeys from '../../localStorageKeys'
import { isProduction } from '../../utils/isProduction'
import { ErrorMessage } from './AppointmentValidation'

const PageWrapper = styled.div`
  width: 100%;
  padding: 3rem 0;
  ${Title} {
    margin-bottom: 3rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    padding: ${({ theme }) =>
      `${theme.spacings[25]} 2rem ${theme.spacings[25]} 0`};
    margin-left: ${({ theme }) => theme.spacings[4]};
    max-width: 552px;

    ${Title} {
      max-width: 444px;
      font-size: clamp(40px, 7vw, 48px);
    }
  }
`

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST || '')

const AppointmentPayment = () => {
  const { appointmentFormData } = useAppointmentForm()
  const history = useHistory()

  const [paymentIntent, setPaymentIntent] =
    useState<Stripe.Response<Stripe.PaymentIntent> | null>(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const totalPrice = localStorage.getItem(LocalStorageKeys.totalPrice)

    if (totalPrice) {
      const body = { totalPrice, appointmentFormData }
      fetch(
        isProduction
          ? process.env.REACT_APP_NETLIFY_FUNCTION_PROD_URL + '/stripe'
          : process.env.REACT_APP_NETLIFY_FUNCTION_DEV_URL + '/stripe',
        {
          body: JSON.stringify(body),
          method: 'POST',
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setPaymentIntent(data)
          setLoading(false)
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
          setError(true)
        })
    } else {
      history.push('/')
    }
  }, [history, appointmentFormData])

  return (
    <PageWrapper>
      <Title>Renseignez vos informations de paiement</Title>

      {loading && (
        <>
          <FormSkeleton />
          <FormSkeleton />
        </>
      )}

      {!loading && !error && (
        <Elements stripe={stripePromise}>
          {paymentIntent && <CheckoutForm paymentIntent={paymentIntent} />}
        </Elements>
      )}

      {error && (
        <ErrorMessage>
          une erreur s'est produite, veuillez réessayer
        </ErrorMessage>
      )}
    </PageWrapper>
  )
}

export default AppointmentPayment
