import { isEqual, format, getDate } from 'date-fns'

import { HolidayDate } from '../components/organisms/modals/ModalCalendar'

const WEEKDAY_CONFIG: Intl.DateTimeFormatOptions = {
  weekday: 'long',
}

const DAY_CONFIG: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  weekday: 'short',
}

const MONTH_CONFIG: Intl.DateTimeFormatOptions = {
  month: 'long',
  year: 'numeric',
}

const FULL_CONFIG: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  weekday: 'long',
  month: 'long',
  year: 'numeric',
}

enum DateFormat {
  day = 'day',
  month = 'month',
  weekday = 'weekday',
  full = 'full',
}

enum SplitDayValues {
  alphabetical = 'alphabetical',
  numerical = 'numerical',
}

type formatDateProps = keyof typeof DateFormat

const optionsConfig = {
  [DateFormat.day]: DAY_CONFIG,
  [DateFormat.month]: MONTH_CONFIG,
  [DateFormat.weekday]: WEEKDAY_CONFIG,
  [DateFormat.full]: FULL_CONFIG,
}

export const formatDate = (format: formatDateProps) => {
  return new Intl.DateTimeFormat('fr-FR', optionsConfig[format])
}

export const splitDay = (day: Date, part: keyof typeof SplitDayValues) => {
  const mapPart = {
    [SplitDayValues.alphabetical]: 0,
    [SplitDayValues.numerical]: 1,
  }

  return formatDate('day').format(new Date(day)).split(' ')[mapPart[part]]
}

export const isTheSameDay = (
  dates: HolidayDate[] | null,
  dayToCompare: Date
) => {
  if (!dates) return false

  const filteredDates = dates?.filter((day) => {
    return isEqual(
      new Date(format(new Date(day.date), 'MM/dd/yyyy')),
      new Date(format(new Date(dayToCompare), 'MM/dd/yyyy'))
    )
  })

  return filteredDates?.length > 0
}

export const isMonthChangeThisWeek = (arr: Date[]) => {
  const filteredArr = arr.filter((day: Date, i: number) => {
    if (i === 0) return false

    const numericDay = getDate(new Date(day))

    return numericDay === 1 ? day : false
  })

  return filteredArr.length >= 1 ? filteredArr[0] : false
}
