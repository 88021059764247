import { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import CloseButton from '../header/CloseButton'

const StyledHeader = styled.header`
  padding: ${({ theme }) => `${theme.spacings[12]} 0 0`};

  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 100%;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.colors.grey[100]};
    z-index: ${({ theme }) => theme.zIndex.background};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    display: flex;
    padding-top: 5rem;
    flex-direction: column;
    width: 80%;

    &::before {
      left: unset;
      right: 0;
      transform: translateX(0);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints['laptop-sm']}) {
    width: 100%;
    padding: ${({ theme }) => `9.75rem ${theme.spacings[15]} 0 0`};
    max-width: calc(360px + ${({ theme }) => theme.spacings[15]});
  }
`

const StyledHeaderTop = styled.div`
  padding: ${({ theme }) => `0 0 ${theme.spacings[4]}`};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    flex-direction: column-reverse;
    padding: 0;
    margin-bottom: ${({ theme }) => theme.spacings[12]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints['laptop-sm']}) {
    margin-bottom: ${({ theme }) => theme.spacings[18]};
  }
`

const StyledTitleWrapper = styled.p`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    flex-direction: column-reverse;
    margin-right: 0;
    margin-top: ${({ theme }) => theme.spacings[15]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints['laptop-sm']}) {
    margin-top: ${({ theme }) => theme.spacings[28]};
  }
`

const StyledSpan = css`
  display: block;
  font-size: 14px;
  line-height: 1.3;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    line-height: 1.5;
  }
`

const StyledSubtitle = styled.span`
  ${StyledSpan}
  margin-top: ${({ theme }) => theme.spacings[1]};

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    font-size: 19px;
    margin-top: ${({ theme }) => theme.spacings[2]};
    color: ${({ theme }) => theme.colors.black[400]};
  }
`

const StyledTitle = styled.span`
  ${StyledSpan}
  font-weight: 500;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    font-size: 24px;
  }
`

const Sticky = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    position: sticky;
    top: 5rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints['laptop-sm']}) {
    top: 9.75rem;
  }
`

export type AsideNavigationProps = {
  title: string
  subtitle: string
  children: ReactNode
  onClose: () => void
  className?: string
}

const AsideNavigation = ({
  title,
  subtitle,
  children,
  onClose,
  className,
}: AsideNavigationProps) => {
  return (
    <StyledHeader className={className}>
      <Sticky>
        <StyledHeaderTop>
          <StyledTitleWrapper>
            <StyledTitle>{title}</StyledTitle>
            <StyledSubtitle>{subtitle}</StyledSubtitle>
          </StyledTitleWrapper>
          <CloseButton color="dark" onClick={onClose} />
        </StyledHeaderTop>
        {children}
      </Sticky>
    </StyledHeader>
  )
}

export default AsideNavigation
