import { ReactNode } from 'react'
import styled from 'styled-components/macro'

import MiniBreadcrumbItem from './MiniBreadcrumbItem'

const Wrapper = styled.div`
  display: flex;

  > * + * {
    margin-left: 0.7rem;

    &:before {
      content: '';
      position: absolute;
      left: -0.4rem;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
      background-color: ${({ theme }) => theme.colors.black[400]};
      width: 3px;
      height: 3px;
      border-radius: 50%;
    }
  }
`

type MiniBreadcrumbProps = {
  children: ReactNode
}

const MiniBreadcrumb = ({ children }: MiniBreadcrumbProps) => {
  return <Wrapper>{children}</Wrapper>
}

MiniBreadcrumb.Item = MiniBreadcrumbItem
export default MiniBreadcrumb
