import { Route, RouteProps } from 'react-router-dom'

import LayoutBlack from '../layouts/LayoutBlack'

const BlackLayoutRoute = (props: RouteProps) => {
  return (
    <LayoutBlack>
      <Route {...props} />
    </LayoutBlack>
  )
}

export default BlackLayoutRoute
