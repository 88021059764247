import { BrowserRouter, Route, Switch } from 'react-router-dom'

import ScrollToTop from '../hooks/scrollToTop'
import AppointmentLayout from '../layouts/AppointmentLayout'
import DescribeLeak from '../pages/DescribeLeak'
import ProposalService from '../pages/ProposalService'
import BlackLayoutRoute from './BlackLayoutRoute'
import DefaultLayoutRoute from './DefaultLayoutRoute'
import Routes, { APPOINTMENT_BASE_URL } from './Routes'

const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <DefaultLayoutRoute
          path={Routes.ProposalService}
          component={ProposalService}
        />
        <Route path={APPOINTMENT_BASE_URL} component={AppointmentLayout} />
        <BlackLayoutRoute
          path={Routes.DescribeLeak}
          component={DescribeLeak}
          exact
        />
      </Switch>
    </BrowserRouter>
  )
}

export default Router
