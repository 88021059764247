import { useHistory } from 'react-router'
import styled from 'styled-components/macro'

import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrowRight.svg'
import Routes from '../../../router/Routes'
import Button from '../../atoms/button/Button'
import Title from '../../atoms/title/Title'
import Header from '../../molecules/header/Header'
import ModalTeleconsultationBenefits from '../ModalTeleconsultationBenefits'
import TeleconsultationSteps from '../TeleconsultationSteps'
import {
  BoldText,
  HeadingWrapper,
  InformationWrapper,
  ModalPageWrapper,
} from './ModalAssurance'

const TABLET_BREAKPOINT = 'tablet'

const SmallTitle = styled.p`
  font-size: 19px;
  font-weight: 500;
`

const HeadingInformation = styled(SmallTitle)`
  margin-top: ${({ theme }) => theme.spacings[6]};
`

const HeadingDescription = styled.p`
  margin-top: ${({ theme }) => theme.spacings[6]};
  width: 100%;
  color: ${({ theme }) => theme.colors.black[400]};

  @media (min-width: ${({ theme }) => theme.breakpoints[TABLET_BREAKPOINT]}) {
    margin-top: ${({ theme }) => theme.spacings[12]};
    max-width: 536px;
  }
`

const BottomWrapper = styled.div`
  padding: 4.5rem 1.5rem;
  margin: 0 auto;
  width: 100%;
  max-width: 424px;
`

const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 19px;
  line-height: 150%;
`

const ActionsWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > * + * {
    margin-top: 2rem;
  }

  ${Button}:last-child {
    color: ${({ theme }) => theme.colors.black[500]};
    &:before {
      background-color: ${({ theme }) => theme.colors.black[500]};
    }
  }
`

type ModalTeleconsultingAppointmentProps = {
  teleconsultationPrice: string
  onClose: () => void
}

const ModalTeleconsultingAppointment = ({
  teleconsultationPrice,
  onClose,
}: ModalTeleconsultingAppointmentProps) => {
  const history = useHistory()

  return (
    <ModalPageWrapper>
      <InformationWrapper>
        <Header variant="dark" onClick={onClose}>
          <BoldText>Prendre rendez-vous</BoldText>
        </Header>

        <HeadingWrapper>
          <Title>
            Vous ne résidez <span>pas dans notre zone</span> d’intervention
          </Title>
          <HeadingInformation>
            Mais nous vous proposons une téléconsultation avec un expert pour{' '}
            {teleconsultationPrice}.
          </HeadingInformation>
          <HeadingDescription>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore
          </HeadingDescription>
        </HeadingWrapper>
      </InformationWrapper>

      <TeleconsultationSteps />
      <ModalTeleconsultationBenefits
        teleconsultationPrice={teleconsultationPrice}
      />

      <BottomWrapper>
        <PriceWrapper>
          <p>Prix</p>
          <p>{teleconsultationPrice}</p>
        </PriceWrapper>

        <ActionsWrapper>
          <Button
            icon={<ArrowIcon />}
            onClick={() => history.push(Routes.AppointmentContact)}
          >
            Ça m’intéresse
          </Button>
          <Button
            shape="ghost"
            onClick={() => history.push(Routes.DescribeLeak)}
          >
            Non merci
          </Button>
        </ActionsWrapper>
      </BottomWrapper>
    </ModalPageWrapper>
  )
}

export default ModalTeleconsultingAppointment
