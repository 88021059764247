import styled from 'styled-components/macro'

import { useAppointmentForm } from '../../../contexts/AppointmentForm'
import LocalStorageKeys from '../../../localStorageKeys'
import Routes from '../../../router/Routes'
import { getMajoratedPrice } from '../../../utils/price'
import Button from '../../atoms/button/Button'
import Card from '../../atoms/card/Card'
import { SelectedDate } from '../AppointmentCalendarIntervention'
import {
  CardHeading,
  Section,
  SectionTitle,
  Separator,
  Subtext,
  TitleContent,
} from './ValidationStyled'

const RedText = styled.span`
  color: ${({ theme }) => theme.colors.primary[500]};
`

type InterventionRecapProps = {
  interventionDates: SelectedDate[]
  coefficientMajoration: number
}

const InterventionRecap = ({
  interventionDates,
  coefficientMajoration,
}: InterventionRecapProps) => {
  const { appointmentFormData } = useAppointmentForm()

  return (
    <Section>
      <SectionTitle>
        <TitleContent>Intervention</TitleContent>
        <Button to={Routes.AppointmentIntervention} shape="ghost">
          Modifier
        </Button>
      </SectionTitle>
      <Card withBorder>
        <CardHeading>Adresse</CardHeading>
        <p>{appointmentFormData?.address}</p>
        <p>{appointmentFormData?.postalCode}</p>

        <Separator />

        <CardHeading>Disponibilités</CardHeading>
        {interventionDates.map((intervention, i) => (
          <p key={i}>
            {intervention.date} - {intervention.hours}
            {intervention.majoration ? (
              <RedText>{` - Majoration +
            ${getMajoratedPrice(
              Number(
                localStorage.getItem(LocalStorageKeys.prestationPrice) || 0
              ),
              coefficientMajoration
            )}`}</RedText>
            ) : (
              ''
            )}
          </p>
        ))}
        <Subtext>
          Un conseiller vous rappelera dans les plus brefs délais pour confirmer
          le créneau retenu
        </Subtext>
      </Card>
    </Section>
  )
}

export default InterventionRecap
