import React from 'react'
import styled from 'styled-components/macro'

import Radio, { RadioProps } from '../../atoms/radio/Radio'

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: ${({ theme }) => theme.spacings[6]};
  }
`

export type RadioGroupProps<T> = {
  children: React.ReactElement<RadioProps>[]
  className?: string
  name: string
  onChange: (value: T) => void
  value?: T
}

const RadioGroup = <T extends string>({
  className,
  children,
  name,
  onChange,
  value,
}: RadioGroupProps<T>) => {
  return (
    <RadioWrapper className={className}>
      {children.map((child) =>
        React.cloneElement(child, {
          key: child.props.value?.toString(),
          checked: value === child.props.value,
          onChange: () => onChange(child.props.value as T),
          name,
        })
      )}
    </RadioWrapper>
  )
}

RadioGroup.Radio = Radio

export default styled(RadioGroup)``
