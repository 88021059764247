import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

const CommonStyle = css<{ active?: boolean }>`
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.black[400]};

  ${({ theme, active }) =>
    active &&
    css`
      color: ${theme.colors.black[500]};
    `}
`

const Wrapper = styled.p`
  ${CommonStyle}
`

const WrapperLink = styled(Link)`
  ${CommonStyle}
  text-decoration: none;
`

type MiniBreadcrumbItemProps = {
  children: ReactNode
  active?: boolean
  to?: string
}

const MiniBreadcrumbItem = ({
  children,
  to,
  active,
}: MiniBreadcrumbItemProps) => {
  return (
    <>
      {to && (
        <WrapperLink to={to} active={active}>
          {children}
        </WrapperLink>
      )}
      {!to && <Wrapper active={active}>{children}</Wrapper>}
    </>
  )
}

export default MiniBreadcrumbItem
