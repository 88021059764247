import { Route, RouteProps } from 'react-router-dom'

import DefaultLayout from '../layouts/DefaultLayout'

const DefaultLayoutRoute = (props: RouteProps) => {
  return (
    <DefaultLayout>
      <Route {...props} />
    </DefaultLayout>
  )
}

export default DefaultLayoutRoute
