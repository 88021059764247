import { airtable } from '../airtable'
import { Text } from '../sdk/Text'

export function getTexts(key: string[], callback: (val: Text[]) => void) {
  const formulaOr = key.map((k) => `{key} = "${k}"`).join(', ')

  airtable<Text>('Textes')
    .select({
      view: 'Grid view',
      filterByFormula: `OR(${formulaOr})`,
    })
    .eachPage(
      function page(records, fetchNextPage) {
        callback(records.map((record) => record.fields))
        fetchNextPage()
      },
      function done(err) {
        if (err) {
          console.error(err)
          return
        }
      }
    )
}
