import { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components/macro'

type StyledProps = {
  level: number
}

const StyledTitle = styled.div.attrs<StyledProps>(({ level }) => ({
  as: `h${level}`,
}))<StyledProps>`
  font-family: ${({ theme }) => theme.typography.headingFamily};
  font-weight: bold;
  font-size: 40px;
  line-height: 1.1;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black[500]};

  > span {
    color: ${({ theme }) => theme.colors.primary[500]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    ${(props) =>
      props.level === 1 &&
      css`
        & {
          font-size: clamp(40px, 7vw, 80px);
        }
      `}
    ${(props) =>
      props.level === 2 &&
      css`
        & {
          font-size: clamp(30px, 7vw, 50px);
        }
      `}
    line-height: 1;
  }
`

export type TitleProps = {
  level: number
} & HTMLAttributes<HTMLHeadingElement>

const Title = ({ level, children, ...rest }: TitleProps) => {
  return (
    <StyledTitle level={level} {...rest}>
      {children}
    </StyledTitle>
  )
}

Title.defaultProps = {
  level: 1,
}

export default styled(Title)``
