import styled from 'styled-components/macro'

import Card from '../../atoms/card/Card'

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3em;

  ${Card} > p {
    margin: 0.4rem 0;
  }
`

export const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

export const TitleContent = styled.h2`
  font-weight: 500;
  font-size: 19px;
  line-height: 150%;
`

export const CardHeading = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  &&& {
    margin-bottom: 0.8rem;
  }
`

export const Subtext = styled.p`
  color: ${({ theme }) => theme.colors.black[400]};
`

export const Separator = styled.div`
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[300]};
  margin: 1.5rem auto;
  width: 100%;
`
