import { isAfter } from 'date-fns'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components/macro'

import { useAppointmentForm } from '../../contexts/AppointmentForm'
import { getTeleconsultationPrice } from '../../queries/getTeleconsultationPrice'
import Routes from '../../router/Routes'
import Modal from '../atoms/modal/Modal'
import Title from '../atoms/title/Title'
import { Bold, SelectedDate } from './AppointmentCalendarIntervention'
import Availabilities from './Availabilities'
import ModalCalendar from './modals/ModalCalendar'

const PageWrapper = styled.div`
  width: 100%;
  padding: 3rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    padding: ${({ theme }) =>
      `${theme.spacings[25]} 2rem ${theme.spacings[25]} 0`};
    margin-left: ${({ theme }) => theme.spacings[4]};
    max-width: 552px;

    ${Title} {
      max-width: 444px;
      font-size: clamp(40px, 7vw, 48px);
    }
  }
`

const AppointmentCalendarTeleconsulting = () => {
  const history = useHistory()
  const { appointmentFormData, saveData } = useAppointmentForm()
  const pageRef = useRef<HTMLDivElement>(null)

  const [isModalCalendarOpen, setModalCalendarOpen] = useState(false)
  const [dates, setDates] = useState<SelectedDate[]>([])
  const [teleconsultationPrice, setTeleconsultationPrice] = useState<
    number | null
  >(null)

  useEffect(() => {
    if (!appointmentFormData?.teleconsultationDates) return

    const dates = appointmentFormData?.teleconsultationDates.filter((date) =>
      isAfter(new Date(date._rawDate), new Date())
    )

    setDates(dates)
  }, [appointmentFormData])

  useEffect(() => {
    getTeleconsultationPrice()
      .then((price) => setTeleconsultationPrice(price))
      .catch((error) => console.error(error))
  }, [])

  const handleClickCTA = useCallback(
    (widthData: boolean) => {
      setDates(widthData ? dates : [])
      saveData?.({
        teleconsultationDates: widthData ? dates : undefined,
        interventionDates: undefined,
      })
      history.push(Routes.AppointmentValidation)
    },
    [saveData, history, dates]
  )

  return (
    <PageWrapper ref={pageRef}>
      <Title>Votre créneau pour la téléconsultation</Title>

      {teleconsultationPrice && (
        <Availabilities
          description={
            <>
              Renseignez vos disponibilités{' '}
              <Bold>(2 créneaux minimum sur des jours différents)</Bold> et nous
              vous confirmerons votre rendez-vous par mail
            </>
          }
          dates={dates}
          setDates={setDates}
          setModalCalendarOpen={setModalCalendarOpen}
          price={teleconsultationPrice}
          teleconsulting
          onClick={() => handleClickCTA(true)}
          ctaWidth="small"
        />
      )}

      {isModalCalendarOpen && teleconsultationPrice && (
        <Modal onClickAway={() => setModalCalendarOpen(false)} size="small">
          <ModalCalendar
            onClose={() => setModalCalendarOpen(false)}
            setDates={setDates}
            dates={dates}
            teleconsulting
            price={teleconsultationPrice}
          />
        </Modal>
      )}
    </PageWrapper>
  )
}

export default AppointmentCalendarTeleconsulting
