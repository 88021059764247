import { useMemo } from 'react'

import AppointmentCalendarIntervention from '../../components/organisms/AppointmentCalendarIntervention'
import AppointmentCalendarTeleconsulting from '../../components/organisms/AppointmentCalendarTeleconsulting'
import LocalStorageKeys from '../../localStorageKeys'

const AppointmentCalendar = () => {
  const component = useMemo(() => {
    const prestationPrice = localStorage.getItem(
      LocalStorageKeys.prestationPrice
    )

    if (prestationPrice) {
      return <AppointmentCalendarIntervention />
    } else {
      return <AppointmentCalendarTeleconsulting />
    }
  }, [])

  return <>{component}</>
}

export default AppointmentCalendar
