import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import theme from '../../../theme/theme'

export enum BreadcrumbItemVariant {
  light = 'light',
  dark = 'dark',
  grey = 'grey',
}

const breadcrumbItemColor = {
  [BreadcrumbItemVariant.light]: theme.colors.white[300],
  [BreadcrumbItemVariant.dark]: theme.colors.black[500],
  [BreadcrumbItemVariant.grey]: theme.colors.black[400],
}

const breadcrumbItemSelectedColor = {
  [BreadcrumbItemVariant.light]: theme.colors.white[500],
  [BreadcrumbItemVariant.dark]: theme.colors.primary[500],
  [BreadcrumbItemVariant.grey]: theme.colors.primary[500],
}

const selectedStyle = (variant: BreadcrumbItemProps['variant']) => css`
  color: ${variant && breadcrumbItemSelectedColor[variant]};
`

type WrapperProps = Pick<BreadcrumbItemProps, 'variant' | 'selected'>

const Wrapper = styled.p<WrapperProps>`
  font-weight: 500;
  color: ${({ variant }) => variant && breadcrumbItemColor[variant]};
  ${({ selected, variant }) => selected && selectedStyle(variant)}
`

type BreadcrumbLinkProps = Pick<BreadcrumbItemProps, 'selected'>

const BreadcrumbLink = styled(Link)<BreadcrumbLinkProps>`
  text-decoration: none;
  color: inherit;
  color: ${({ theme, selected }) => !selected && theme.colors.black[500]};
`

export type BreadcrumbItemProps = {
  children: ReactNode
  variant?: keyof typeof BreadcrumbItemVariant
  selected?: boolean
  to?: string
}

const BreadcrumbItem = ({
  children,
  variant = 'grey',
  selected,
  to,
}: BreadcrumbItemProps) => {
  return (
    <Wrapper variant={variant} selected={selected}>
      {to && (
        <BreadcrumbLink to={to} selected={selected}>
          {children}
        </BreadcrumbLink>
      )}
      {!to && children}
    </Wrapper>
  )
}

export default BreadcrumbItem
