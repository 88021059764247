import { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components/macro'

import { ReactComponent as CloseIcon } from '../../../assets/icons/cross.svg'
import theme from '../../../theme/theme'

export enum CloseButtonColor {
  light = 'light',
  dark = 'dark',
}

const closeButtonColors = {
  [CloseButtonColor.light]: theme.colors.grey[400],
  [CloseButtonColor.dark]: theme.colors.black[500],
}

const StyledCloseButton = styled.button<StyledCloseButtonProps>`
  width: 2rem;
  height: 2rem;
  position: relative;
  flex: 0 0 auto;
  background-color: ${({ color }) => color && closeButtonColors[color]};
  color: ${({ theme }) => theme.colors.white[500]};
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.15s ease-out;

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12.8px;
    height: 12.8px;
    transform: translate(-50%, -50%);
  }
`

export type StyledCloseButtonProps = {
  color?: keyof typeof CloseButtonColor
}

export type CloseButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  StyledCloseButtonProps

const CloseButton = ({ color = 'light', ...rest }: CloseButtonProps) => {
  return (
    <StyledCloseButton {...rest} type="button" color={color}>
      <CloseIcon />
    </StyledCloseButton>
  )
}

export default CloseButton
