import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled, { css } from 'styled-components/macro'

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowRight.svg'
import { ReactComponent as Searchicon } from '../../assets/icons/search.svg'
import Button from '../../components/atoms/button/Button'
import Card from '../../components/atoms/card/Card'
import Modal from '../../components/atoms/modal/Modal'
import Radio from '../../components/atoms/radio/Radio'
import Title from '../../components/atoms/title/Title'
import ModalAssurance from '../../components/organisms/modals/ModalAssurance'
import {
  CardTitle,
  StyledCard,
} from '../../components/organisms/ProposalService/ProposalServiceStyled'
import ContactRecap from '../../components/organisms/Validation/ContactRecap'
import InterventionRecap from '../../components/organisms/Validation/InterventionRecap'
import TeleconsultationRecap from '../../components/organisms/Validation/TeleconsultationRecap'
import { Separator } from '../../components/organisms/Validation/ValidationStyled'
import { useAppointmentForm } from '../../contexts/AppointmentForm'
import LocalStorageKeys from '../../localStorageKeys'
import { getCoefficientMajoration } from '../../queries/getCoefficientMajoration'
import { getTeleconsultationPrice } from '../../queries/getTeleconsultationPrice'
import { getTexts } from '../../queries/getTexts'
import Routes from '../../router/Routes'
import { Text } from '../../sdk/Text'
import { getMajoratedPrice } from '../../utils/price'

const PageWrapper = styled.div`
  width: 100%;
  padding: 3rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    padding: ${({ theme }) =>
      `${theme.spacings[25]} 2rem ${theme.spacings[25]} 0`};
    margin-left: ${({ theme }) => theme.spacings[4]};
    max-width: 552px;

    ${Title} {
      font-size: clamp(40px, 7vw, 48px);
      max-width: 455px;
    }
  }
`

const H2 = styled.h2<{ smallMargin?: boolean }>`
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  margin: ${({ smallMargin }) => (smallMargin ? '1.5rem 0' : ' 3rem 0 2.5rem')};
`

const PriceWrapper = styled.div<{ red?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;

  ${({ red, theme }) =>
    red &&
    css`
      color: ${theme.colors.primary[500]};
    `}
`

const AssuranceWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
`

const Price = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  flex: 0 0 auto;
`

const Bold = styled.p`
  font-weight: 500;
`

const TeleconsultationInfos = styled.p`
  margin-top: 3rem;
`

const PaymentDescription = styled.p`
  font-weight: 500;
  margin: 2.5rem 0;
`

const ConfirmButton = styled(Button)`
  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    width: 300px;
  }

  margin-top: 2.5rem;
`

const ReglementationContent = styled.p`
  color: ${({ theme }) => theme.colors.black[400]};
  font-size: 14px;
  line-height: 150%;
  margin-left: 0.5rem;
`

const TotalWrapper = styled.div`
  margin-top: 1.5rem;
`

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.primary[500]};
  margin-top: 0.5rem;
`

const AppointmentValidation = () => {
  const history = useHistory()

  const [isRadioCheck, setRadioCheck] = useState(false)
  const [hasError, setError] = useState(false)
  const [isModalAssuranceOpen, setModalAssuranceOpen] = useState(false)
  const { appointmentFormData } = useAppointmentForm()
  const [texts, setTexts] = useState<Text[]>([])
  const [teleconsultationPrice, setTeleconsultationPrice] = useState(0)
  const [coefficientMajoration, setCoefficientMajoration] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalOnlyInterventionPrice, setTotalOnlyInterventionPrice] =
    useState(0)

  useEffect(() => {
    getTexts(
      [
        'insurance_title',
        'insurance_subtitle',
        'insurance_card1_title',
        'insurance_card1_content',
        'insurance_card2_title',
        'insurance_card2_content',
      ],
      setTexts
    )

    getTeleconsultationPrice().then((price) => setTeleconsultationPrice(price))
    getCoefficientMajoration().then((coefficient) =>
      setCoefficientMajoration(coefficient)
    )
  }, [])

  useEffect(() => {
    const prestationPrice = localStorage.getItem(
      LocalStorageKeys.prestationPrice
    )
    const priceParking = localStorage.getItem(LocalStorageKeys.priceParking)

    let total = 0
    if (prestationPrice) {
      total += Number(prestationPrice)

      if (
        appointmentFormData?.interventionDates?.find((date) => date.majoration)
      )
        total += Number(prestationPrice) * coefficientMajoration
      if (
        appointmentFormData?.teleconsultationDates?.find(
          (date) => date.majoration
        )
      )
        total += Number(teleconsultationPrice) * coefficientMajoration
    }
    if (appointmentFormData?.parking) total += Number(priceParking)

    setTotalPrice(total)
  }, [teleconsultationPrice, appointmentFormData, coefficientMajoration])

  useEffect(() => {
    if (!appointmentFormData?.interventionDates) {
      let total = teleconsultationPrice

      if (
        appointmentFormData?.teleconsultationDates?.find(
          (date) => date.majoration
        )
      ) {
        total += Number(teleconsultationPrice) * coefficientMajoration
      }

      setTotalOnlyInterventionPrice(total)
    }
  }, [teleconsultationPrice, appointmentFormData, coefficientMajoration])

  // Set the total price on local storage
  useEffect(() => {
    if (appointmentFormData?.interventionDates) {
      localStorage.setItem(LocalStorageKeys.totalPrice, totalPrice.toString())
    } else if (!appointmentFormData?.interventionDates) {
      localStorage.setItem(
        LocalStorageKeys.totalPrice,
        totalOnlyInterventionPrice.toString()
      )
    }
  }, [
    appointmentFormData,
    totalPrice,
    totalOnlyInterventionPrice,
    teleconsultationPrice,
  ])

  const getText = (key: string) =>
    texts.find((t) => t.key === key)?.content || ''

  return (
    <>
      <PageWrapper>
        <Title>Vérifiez et confirmez votre demande</Title>

        <ContactRecap />

        {appointmentFormData?.interventionDates && (
          <InterventionRecap
            interventionDates={appointmentFormData.interventionDates}
            coefficientMajoration={coefficientMajoration}
          />
        )}

        {appointmentFormData?.teleconsultationDates && (
          <TeleconsultationRecap
            teleconsultationDates={appointmentFormData.teleconsultationDates}
            teleconsultationPrice={teleconsultationPrice}
            coefficientMajoration={coefficientMajoration}
          />
        )}

        <H2>Détail de l’intervention</H2>

        {appointmentFormData?.teleconsultationDates && (
          <>
            <PriceWrapper>
              <p>Téléconsultation</p>
              <Price>
                {new Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(teleconsultationPrice)}
              </Price>
            </PriceWrapper>
            {appointmentFormData?.interventionDates && (
              <PriceWrapper red>
                <p>Remboursement téléconsultation*</p>
                <Price>
                  -{' '}
                  {new Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(teleconsultationPrice)}
                </Price>
              </PriceWrapper>
            )}
          </>
        )}

        {localStorage.getItem(LocalStorageKeys.prestationPrice) && (
          <PriceWrapper>
            <p>Intervention sur place</p>
            <Price>
              {new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
              }).format(
                Number(localStorage.getItem(LocalStorageKeys.prestationPrice))
              )}
            </Price>
          </PriceWrapper>
        )}

        {/* si le prix total est plus grand que le prix de la prestation, c'est qu'il y a des frais en plus */}
        {appointmentFormData?.interventionDates &&
          totalPrice >
            Number(localStorage.getItem(LocalStorageKeys.prestationPrice)) && (
            <H2 smallMargin>Ce que vous pourriez payer en plus</H2>
          )}
        {!appointmentFormData?.interventionDates &&
          totalOnlyInterventionPrice > teleconsultationPrice && (
            <H2 smallMargin>Ce que vous pourriez payer en plus</H2>
          )}

        {appointmentFormData?.teleconsultationDates?.find(
          (date) => date.majoration
        ) && (
          <PriceWrapper>
            <p>Majoration du créneau horaire de la téléconsultation</p>
            <Price>
              +{' '}
              {getMajoratedPrice(teleconsultationPrice, coefficientMajoration)}
            </Price>
          </PriceWrapper>
        )}

        {appointmentFormData?.interventionDates?.find(
          (date) => date.majoration
        ) && (
          <PriceWrapper>
            <p>Majoration du créneau horaire de l’intervention</p>
            <Price>
              +{' '}
              {getMajoratedPrice(
                Number(localStorage.getItem(LocalStorageKeys.prestationPrice)),
                coefficientMajoration
              )}
            </Price>
          </PriceWrapper>
        )}

        {appointmentFormData?.interventionDates &&
          appointmentFormData?.parking && (
            <PriceWrapper>
              <p>Stationnement du technicien</p>
              <Price>
                +{' '}
                {new Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(appointmentFormData.parking)}
              </Price>
            </PriceWrapper>
          )}

        <TotalWrapper>
          <Separator />

          <PriceWrapper>
            <Bold>Total</Bold>
            {appointmentFormData?.interventionDates && (
              <Price>
                {new Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(totalPrice)}
              </Price>
            )}
            {!appointmentFormData?.interventionDates && (
              <Price>
                {new Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(totalOnlyInterventionPrice)}
              </Price>
            )}
          </PriceWrapper>
        </TotalWrapper>

        {appointmentFormData?.interventionDates && (
          <AssuranceWrapper>
            <Card color="grey">
              <StyledCard>
                <CardTitle>
                  Cette intervention peut être prise en charge par votre
                  assurance
                </CardTitle>
                <Searchicon onClick={() => setModalAssuranceOpen(true)} />
              </StyledCard>
            </Card>
          </AssuranceWrapper>
        )}

        {appointmentFormData?.interventionDates &&
          appointmentFormData?.teleconsultationDates && (
            <TeleconsultationInfos>
              * Si l’intervention sur place d’un technicien reste nécessaire
              après la téléconsultation, le montant de la téléconsultation vous
              sera remboursée.
            </TeleconsultationInfos>
          )}

        <PaymentDescription>
          En cliquant sur confirmer, vous serez redirigé vers une solution de
          paiement pour laisser votre empreinte de carte. Celle-ci nous
          permettra de procéder au réglement après l’intervention du technicien.
        </PaymentDescription>

        <Radio
          onWrapperClick={() => setRadioCheck(!isRadioCheck)}
          checked={isRadioCheck}
          readOnly
        >
          <ReglementationContent>
            Conformément à la réglementation sur la Protection des données
            personnelles, j’autorise CHASSEUR DE FUITES® à utiliser les
            coordonnées que je renseigne afin de traiter ma demande. Ces données
            ne seront jamais communiquées à des tiers.
          </ReglementationContent>
        </Radio>

        {hasError && (
          <ErrorMessage>
            Vous devez accepter les conditions pour valider cette étape.
          </ErrorMessage>
        )}

        <ConfirmButton
          onClick={() => {
            if (!isRadioCheck) {
              setError(true)
              return
            }
            setError(false)
            history.push(Routes.AppointmentPayment)
          }}
          icon={<ArrowIcon />}
        >
          Confirmer
        </ConfirmButton>
      </PageWrapper>

      {isModalAssuranceOpen && (
        <Modal onClickAway={() => setModalAssuranceOpen(false)}>
          <ModalAssurance
            title={getText('insurance_title')}
            subtitle={getText('insurance_subtitle')}
            card1Title={getText('insurance_card1_title')}
            card1Content={getText('insurance_card1_content')}
            card2Title={getText('insurance_card2_title')}
            card2Content={getText('insurance_card2_content')}
            onClose={() => setModalAssuranceOpen(false)}
          />
        </Modal>
      )}
    </>
  )
}

export default AppointmentValidation
