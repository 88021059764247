const zIndex = {
  background: '-1',
  page: '10',
  layoutDefault: '100',
  menu: '200',
  overlay: '300',
  modal: '400',
}

export default zIndex
