/* eslint-disable no-template-curly-in-string */
import { createContext, FC, useContext, useState } from 'react'
import * as yup from 'yup'

import { SelectedDate } from '../components/organisms/AppointmentCalendarIntervention'
import LocalStorageKeys from '../localStorageKeys'

yup.setLocale({
  mixed: {
    required: 'Ce champ est obligatoire',
  },
  string: {
    email: "L'email renseigné n'est pas valide",
  },
})

export type AppointmentFormData = {
  firstname?: string
  lastname?: string
  email?: string
  phone?: string
  address?: string
  postalCode?: string
  parking?: number
  majoration?: number
  interventionDates?: SelectedDate[]
  teleconsultationDates?: SelectedDate[]
  teleconsultationCheck?: boolean
}

type AppointmentFormContextProps = {
  appointmentFormData: AppointmentFormData
  saveData: (data: AppointmentFormData) => void
}

export const AppointmentFormContext = createContext<
  Partial<AppointmentFormContextProps>
>({})

export const AppointmentFormProvider: FC = ({ children }) => {
  const [appointmentFormData, setAppointmentFormData] =
    useState<AppointmentFormData>(() => {
      const data = localStorage.getItem(LocalStorageKeys.appointmentFormData)
      if (data) {
        return JSON.parse(data)
      }

      return {}
    })

  const saveData = (data: AppointmentFormData) => {
    const newData = { ...appointmentFormData, ...data }
    setAppointmentFormData(newData)
    localStorage.setItem(
      LocalStorageKeys.appointmentFormData,
      JSON.stringify(newData)
    )
  }

  return (
    <AppointmentFormContext.Provider value={{ appointmentFormData, saveData }}>
      {children}
    </AppointmentFormContext.Provider>
  )
}

export function useAppointmentForm() {
  return useContext(AppointmentFormContext)
}
