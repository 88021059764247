import { FieldSet } from 'airtable'
import { useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import { ReactComponent as Calendaricon } from '../../../assets/icons/calendar.svg'
import Routes from '../../../router/Routes'
import { Answer } from '../../../sdk/Answer'
import { formatPrice } from '../../../utils/price'
import Button from '../../atoms/button/Button'
import Card from '../../atoms/card/Card'
import Modal from '../../atoms/modal/Modal'
import Strong from '../../atoms/strong/Strong'
import ModalTeleconsultingAppointment from '../modals/ModalTeleconsultingAppointment'
import {
  CardInfo,
  CardWrapper,
  Price,
  SubPrice,
  InnerContent,
  ZipInfo,
  PostalWrapper,
} from './ProposalServiceStyled'
import RightContent from './RightContent'
import RightContentAnswer from './RightContentAnswer'
import RightContentIZ from './RightContentIZ'
import RightContentIZAnswer from './RightContentIZAnswer'

const RightSkeleton = () => (
  <>
    <Price>
      <Skeleton count={1} />
      <SubPrice>
        <Skeleton count={1} />
      </SubPrice>
    </Price>
    <CardWrapper>
      <Card color="white">
        <Skeleton count={2} style={{ width: '100%' }} />
      </Card>

      <Card color="white">
        <Skeleton count={1} />
        <CardInfo>
          <Skeleton count={2} />
        </CardInfo>
      </Card>
    </CardWrapper>
  </>
)

type ProposalServiceContentProps = {
  price: FieldSet | undefined
  answer: Answer | undefined
  city: string | null
  postalCode: string | null
  notInZone?: boolean
  teleconsultationPrice: string
  isLoadingPrice?: boolean
  changeStep: (val: string) => void
}

const ProposalServiceContentPrice = ({
  price,
  answer,
  city,
  postalCode,
  notInZone,
  teleconsultationPrice,
  isLoadingPrice,
  changeStep,
}: ProposalServiceContentProps) => {
  const [
    isModalTeleconsultingAppointmentOpen,
    setModalTeleconsultingAppointmentOpen,
  ] = useState(false)

  const resultComponent = useMemo(() => {
    // réponse + ZIP
    if (answer && price?.['price_' + answer.key]) {
      return (
        <RightContentIZAnswer
          price={formatPrice(price['price_' + answer.key] as number)}
          teleconsultationPrice={teleconsultationPrice}
        />
      )
    }

    // réponse + pas de zip
    if (answer && !price) {
      return (
        <RightContentAnswer
          price={teleconsultationPrice}
          teleconsultationPrice={teleconsultationPrice}
        />
      )
    }

    // pas de réponse + ZIP
    if (price && !answer) {
      return (
        <RightContentIZ
          price={teleconsultationPrice}
          teleconsultationPrice={teleconsultationPrice}
        />
      )
    }

    return (
      <RightContent
        price={teleconsultationPrice}
        teleconsultationPrice={teleconsultationPrice}
      />
    )
  }, [price, answer, teleconsultationPrice])

  const messageZipInfo = useMemo(() => {
    return price ? (
      <>
        Le tarif affiché est celui que nous appliquons à{' '}
        <Strong weight="medium">{[city, postalCode].join(' : ')}</Strong>
      </>
    ) : (
      <>
        Votre code postal ({postalCode}) ne couvre pas notre zone de prise en
        charge.
      </>
    )
  }, [price, city, postalCode])

  return (
    <>
      <InnerContent>
        <PostalWrapper>
          <ZipInfo>{messageZipInfo}</ZipInfo>
          <Button
            color="primary"
            shape="ghost"
            size="sm"
            onClick={() => changeStep('postal')}
          >
            Modifier
          </Button>
        </PostalWrapper>

        {isLoadingPrice && <RightSkeleton />}
        {!isLoadingPrice && resultComponent}

        <Button
          color="primary"
          size="sm"
          icon={<Calendaricon />}
          to={!notInZone ? Routes.AppointmentContact : undefined}
          onClick={
            notInZone
              ? () => setModalTeleconsultingAppointmentOpen(true)
              : undefined
          }
        >
          Prendre RDV
        </Button>
      </InnerContent>
      {isModalTeleconsultingAppointmentOpen && (
        <Modal onClickAway={() => setModalTeleconsultingAppointmentOpen(false)}>
          <ModalTeleconsultingAppointment
            teleconsultationPrice={teleconsultationPrice}
            onClose={() => setModalTeleconsultingAppointmentOpen(false)}
          />
        </Modal>
      )}
    </>
  )
}

export default ProposalServiceContentPrice
