import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components/macro'

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowChevron.svg'
import { useCalendar } from '../../hooks/useCalendar'
import { formatDate, splitDay } from '../../utils/date'

const Wrapper = styled.div`
  height: 134px;
`

const CalendarTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
`

const Month = styled.p`
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
`

const Week = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-top: ${({ theme }) => theme.spacings[6]};
`

type DayProps = {
  selected?: boolean
  last?: boolean
}

const Day = styled.div<DayProps>`
  --color: ${({ selected, theme }) =>
    selected ? theme.colors.white[500] : theme.colors.black[500]};
  --bg-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary[500] : theme.colors.grey[100]};

  color: var(--color);

  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  ${({ last }) => last && `grid-column-start: 7;`}
`

const AlphabeticalDay = styled.p`
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.colors.black[500]};
`
const NumericalDay = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacings[4]};
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: var(--bg-color);

  & span {
    color: var(--color);
    font-weight: 500;
    font-size: 12px;
  }
`

const NextArrow = styled.button`
  color: ${({ theme }) => theme.colors.black[500]};
  background-color: ${({ theme }) => theme.colors.grey[100]};
  margin-top: ${({ theme }) => theme.spacings[4]};
  width: 38px;
  height: 38px;
  border: none;
  outline: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  transition: background-color 0.15s ease-out;

  & svg {
    width: 14px;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.grey[200]};
    }
  }
`

const PrevArrow = styled(NextArrow)`
  grid-column-start: 1;
  & svg {
    transform: rotate(180deg);
  }
`

export type CalendarHeaderPorps = {
  selectedDate: Date | null
  setSelectedDate: Dispatch<SetStateAction<Date | null>>
}

const CalendarHeader = ({
  setSelectedDate,
  selectedDate,
}: CalendarHeaderPorps) => {
  const { history, getNextWeek } = useCalendar()
  const [index, setIndex] = useState(0)

  useEffect(() => {
    history?.[index] && setSelectedDate(history[index][0])
  }, [history, index, setSelectedDate])

  const handleNextClick = useCallback(() => {
    const nextIndex = index + 1
    setIndex(nextIndex)
    getNextWeek(nextIndex)
  }, [getNextWeek, index])

  const handlePreviousClick = useCallback(() => {
    setIndex((pre) => pre - 1)
  }, [])

  return (
    <Wrapper>
      {history?.[index] && (
        <>
          <CalendarTop>
            <Month>{formatDate('month').format(history[index][0])}</Month>
          </CalendarTop>

          <Week>
            {index > 0 && (
              <Day>
                <AlphabeticalDay>PREC.</AlphabeticalDay>
                <PrevArrow onClick={handlePreviousClick}>
                  <ArrowIcon />
                </PrevArrow>
              </Day>
            )}

            {history[index].map((d, i) => (
              <Day
                key={i}
                selected={(i === 0 && !selectedDate) || selectedDate === d}
                onClick={() => setSelectedDate(d)}
              >
                <AlphabeticalDay>{splitDay(d, 'alphabetical')}</AlphabeticalDay>
                <NumericalDay>
                  <span>{splitDay(d, 'numerical')}</span>
                </NumericalDay>
              </Day>
            ))}

            <Day last={true}>
              <AlphabeticalDay>SUIV.</AlphabeticalDay>
              <NextArrow onClick={handleNextClick}>{<ArrowIcon />}</NextArrow>
            </Day>
          </Week>
        </>
      )}
    </Wrapper>
  )
}

export default CalendarHeader
