import { forwardRef, InputHTMLAttributes } from 'react'
import styled, { css } from 'styled-components/macro'

import { ReactComponent as LockerIcon } from '../../../assets/icons/locker.svg'

const errorStyle = css`
  --borderColor: ${({ theme }) => theme.colors.danger[500]};

  &:focus-within {
    --borderColor: ${({ theme }) => theme.colors.danger[500]};
  }
`

const readOnlyStyle = css`
  --borderColor: ${({ theme }) => theme.colors.grey[400]};

  &:focus-within {
    --borderColor: ${({ theme }) => theme.colors.grey[400]};
  }
`

type WrapperProps = Pick<InputProps, 'error' | 'readOnly'>

const Wrapper = styled.div<WrapperProps>`
  --color: ${({ theme }) => theme.colors.black[500]};
  --borderColor: ${({ theme }) => theme.colors.grey[300]};
  --backgroundColor: ${({ theme }) => theme.colors.white[500]};

  position: relative;
  display: flex;
  background-color: var(--backgroundColor);
  border: 1px solid var(--borderColor);
  height: 69px;
  border-radius: 5px;

  &:focus-within {
    --borderColor: ${({ theme }) => theme.colors.grey[400]};
  }

  ${({ error }) => error && errorStyle};
  ${({ readOnly }) => readOnly && readOnlyStyle};
`

const RightElement = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px 0 0;
  font-size: 1.5rem;
`

const StyledInput = styled.input`
  color: var(--color);
  padding: 24px;
  width: 100%;
  outline: none;
  border: none;
  background-color: transparent;

  &::placeholder {
    color: ${({ theme }) => theme.colors.black[400]};
  }
`

export type InputProps = {
  right?: React.ReactNode
  error?: boolean
  className?: string
} & InputHTMLAttributes<HTMLInputElement>

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ error, right, className, ...rest }, ref) => {
    return (
      <Wrapper error={error} readOnly={rest.readOnly} className={className}>
        <StyledInput {...rest} ref={ref} />
        {right && <RightElement>{right}</RightElement>}
        {rest.readOnly && (
          <RightElement>
            <LockerIcon />
          </RightElement>
        )}
      </Wrapper>
    )
  }
)

export default styled(Input)``
