import { ComponentProps } from 'react'
import styled, { css } from 'styled-components/macro'
import SwiperCore, { Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.min.css'

SwiperCore.use([Pagination])

const paginationStyles = {
  default: css`
    &-active {
      background-color: ${({ theme }) => theme.colors.black[500]};
    }
  `,
  white: css`
    background-color: ${({ theme }) => theme.colors.white[500]};

    &-active {
      background-color: ${({ theme }) => theme.colors.white[500]};
    }
  `,
}

const StyledSwiper = styled(Swiper)<CarouselProps>`
  padding-bottom: ${({ theme }) => theme.spacings[16]};

  .swiper-wrapper {
    min-height: 100%;
  }

  .swiper-slide {
    height: auto !important;

    & > * {
      height: 100%;
    }
  }

  .swiper-pagination-bullet {
    ${({ variant }) => paginationStyles[variant || 'default']}
  }
`

export type CarouselProps = ComponentProps<typeof Swiper> & {
  variant?: 'default' | 'white'
}

const Carousel = (props: CarouselProps) => {
  return (
    <StyledSwiper
      slidesPerView={1.05}
      spaceBetween={10}
      pagination={{ clickable: true }}
      {...props}
    />
  )
}

Carousel.Item = SwiperSlide
export default Carousel
