import { airtable } from '../airtable'
import { TeleconsultationPrice } from '../sdk/TeleconsultationPrice'

export async function getTeleconsultationPrice() {
  const records = await airtable<TeleconsultationPrice>('Prix téléconsultation')
    .select({
      view: 'Grid view',
    })
    .all()

  return records?.[0].get('price')
}
