import styled from 'styled-components/macro'

import TeleconsultingImg from '../../assets/images/teleconsultation.png'
import OrderedList from '../molecules/orderedList/OrderedList'

const ImgWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    flex: 1;
    width: 50%;
    height: auto;
    padding-bottom: 0;
  }
`

const Img = styled.img`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    position: relative;
    top: unset;
    left: unset;
    transform: translate(0, 0);
  }
`

const StepsWrapper = styled.div`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    display: flex;
    flex-direction: row-reverse;
  }
`

const ListWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacings[18]} ${theme.spacings[6]}`};

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    width: 50%;
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${({ theme }) =>
      `0 ${theme.spacings[18]} 0 ${theme.spacings[23]}`};
  }
`

const TeleconsultationSteps = () => {
  return (
    <StepsWrapper>
      <ImgWrapper>
        <Img src={TeleconsultingImg} alt="teleconsulting" />
      </ImgWrapper>

      <ListWrapper>
        <OrderedList>
          <OrderedList.Item>Sélectionnez votre créneau</OrderedList.Item>
          <OrderedList.Item>
            Réglez la prestation sur un terminal sécurisé
          </OrderedList.Item>
          <OrderedList.Item>
            Recevez votre lien de téléconsultation par email
          </OrderedList.Item>
          <OrderedList.Item active>
            Un expert vous accompagne en visioconférence
          </OrderedList.Item>
        </OrderedList>
      </ListWrapper>
    </StepsWrapper>
  )
}

export default TeleconsultationSteps
