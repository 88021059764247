import { FieldSet } from 'airtable'
import { ReactNode, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useHistory, useLocation } from 'react-router'

import Button from '../components/atoms/button/Button'
import Title from '../components/atoms/title/Title'
import Breadcrumb from '../components/molecules/breadcrumb/Breadcrumb'
import CloseButton from '../components/molecules/header/CloseButton'
import Header from '../components/molecules/header/Header'
import ProposalServiceContentPostal from '../components/organisms/ProposalService/ProposalServiceContentPostal'
import ProposalServiceContentPrice from '../components/organisms/ProposalService/ProposalServiceContentPrice'
import {
  PageWrapper,
  HeaderWrapper,
  ContentWrapper,
  HeadingDescription,
  InformationWrapper,
  HeadingWrapper,
  CloseWrapperDesktop,
} from '../components/organisms/ProposalService/ProposalServiceStyled'
import LocalStorageKeys from '../localStorageKeys'
import { getAnswer } from '../queries/getAnswer'
import { getAnswerByKey } from '../queries/getAnswerByKey'
import { getPrices } from '../queries/getPrices'
import { getTeleconsultationPrice } from '../queries/getTeleconsultationPrice'
import Routes from '../router/Routes'
import { Answer } from '../sdk/Answer'

const ProposalService = () => {
  const history = useHistory()
  const location =
    useLocation<{ answer?: string; city?: string; postalCode?: string }>()
  const [postalCode, setPostalCode] = useState(() =>
    localStorage.getItem(LocalStorageKeys.postalCode)
  )
  const [city, setCity] = useState(() =>
    localStorage.getItem(LocalStorageKeys.city)
  )
  const [teleconsultationPrice, setTeleconsultationPrice] = useState('')
  const [answer, setAnswer] = useState<Answer>()
  const [price, setPrice] = useState<FieldSet>()
  const [isAnswerLoading, setIsAnswerLoading] = useState(true)
  const [isLoadingPrice, setIsLoadingPrice] = useState(true)
  const [currentStepProposalService, setCurrentStepProposalService] =
    useState<string>(postalCode ? 'price' : 'postal')

  // Fetch answer
  useEffect(() => {
    async function fetchPage() {
      setIsAnswerLoading(true)
      const describeLeakForm = localStorage.getItem(
        LocalStorageKeys.describeLeakForm
      )

      const searchParams = new URLSearchParams(location.search)
      const answerId = searchParams.get('answer')

      if (answerId) {
        setAnswer(await getAnswerByKey(answerId))
      } else if (describeLeakForm) {
        const formData = JSON.parse(describeLeakForm)
        const key =
          formData.problem.value +
          formData.nature.value +
          formData.location.value

        setAnswer(await getAnswer(key))
      } else {
        history.push(Routes.DescribeLeak)
      }
      setIsAnswerLoading(false)
    }

    fetchPage()
  }, [history, location])

  // Fetch consultation price and all prices from postal code
  useEffect(() => {
    async function fetchPrices() {
      if (!postalCode) return
      setIsLoadingPrice(true)
      setTeleconsultationPrice(
        new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR',
        }).format(await getTeleconsultationPrice())
      )
      const prices = await getPrices(postalCode)
      setPrice(prices)
      setIsLoadingPrice(false)
    }
    fetchPrices()
  }, [postalCode])

  // Save prices and answer in localStorage
  useEffect(() => {
    if (answer) {
      localStorage.setItem(LocalStorageKeys.answer, JSON.stringify(answer))
    } else {
      localStorage.removeItem(LocalStorageKeys.answer)
    }

    if (answer && price?.['price_' + answer.key]) {
      localStorage.setItem(
        LocalStorageKeys.prestationPrice,
        price['price_' + answer.key] as string
      )
    } else {
      localStorage.removeItem(LocalStorageKeys.prestationPrice)
    }

    if (price) {
      localStorage.setItem(
        LocalStorageKeys.priceParking,
        price.price_parking as string
      )
    }
  }, [price, answer])

  const getComponentCurrentStep = () => {
    const steps: { [key: string]: ReactNode } = {
      price: (
        <ProposalServiceContentPrice
          city={city}
          postalCode={postalCode}
          notInZone={answer && !price}
          teleconsultationPrice={teleconsultationPrice}
          isLoadingPrice={isLoadingPrice}
          price={price}
          answer={answer}
          changeStep={(step: string) => setCurrentStepProposalService(step)}
        />
      ),
      postal: (
        <ProposalServiceContentPostal
          setPostalCode={setPostalCode}
          setCity={setCity}
          changeStep={(step: string) => setCurrentStepProposalService(step)}
        />
      ),
    }

    return steps[currentStepProposalService]
  }

  const titleResponse = !answer?.title
    ? 'Téléconsultez un expert pour affiner le diagnostic'
    : answer.title

  const subtitleResponse =
    postalCode && !price
      ? `Malheureusement, nous n'intervenons pas encore dans votre secteur. Nous vous proposons une téléconsultation avec l'œil d'un spécialiste qui sera capable de vous apporter les réponses pour solutionner votre problème.`
      : answer?.subtitle ||
        `Vous n'êtes pas sûr de votre problème ? Nous vous proposons une téléconsultation pour affiner le diagnostic. Celle ci sera déductible si une intervention sur place reste nécessaire par la suite. `

  const LeftSkeleton = () => (
    <>
      <Title>
        <Skeleton count={3} />
      </Title>
      <HeadingDescription>
        <Skeleton count={2} />
      </HeadingDescription>
    </>
  )

  return (
    <>
      <PageWrapper>
        <HeaderWrapper>
          <Header
            variant="dark"
            onClick={() => history.push(Routes.DescribeLeak)}
          >
            <Breadcrumb variant="dark">
              <Breadcrumb.Item to="/">1. Décrire ma fuite</Breadcrumb.Item>
              <Breadcrumb.Item>2. Votre besoin</Breadcrumb.Item>
            </Breadcrumb>
          </Header>
        </HeaderWrapper>

        <InformationWrapper>
          <HeadingWrapper>
            {isAnswerLoading && <LeftSkeleton />}
            {!isAnswerLoading && (
              <>
                <Title>{titleResponse}</Title>
                <HeadingDescription>{subtitleResponse}</HeadingDescription>
                {answer?.link && (
                  <Button
                    color="primary"
                    shape="ghost"
                    size="sm"
                    href={price && answer?.link}
                    target="_blank"
                  >
                    Consulter la fiche intervention
                  </Button>
                )}
              </>
            )}
          </HeadingWrapper>
        </InformationWrapper>

        <CloseWrapperDesktop>
          <CloseButton
            color="dark"
            onClick={() => history.push(Routes.DescribeLeak)}
          />
        </CloseWrapperDesktop>

        <ContentWrapper>{getComponentCurrentStep()}</ContentWrapper>
      </PageWrapper>
    </>
  )
}

export default ProposalService
