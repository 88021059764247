import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { useCombobox, UseComboboxStateChange } from 'downshift'
import { Dispatch, SetStateAction, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrowRight.svg'
import LocalStorageKeys from '../../../localStorageKeys'
import Input from '../../atoms/input/Input'
import Title from '../../atoms/title/Title'
import FormGroup from '../../molecules/formGroup/FormGroup'
import {
  Description,
  Form,
  InnerContent,
  Label,
  Select,
  StyledOption,
  StyledOptions,
  SubmitButton,
} from './ProposalServiceStyled'

type FormData = {
  postalCode: string | null
  city: string
}

const cityErrorMessage = 'Selectionnez une ville lié à votre code postal'

const schema = yup.object().shape({
  postalCode: yup.string().length(5, 'Le code postal est invalide').required(),
  city: yup.string().required(cityErrorMessage),
})

type ProposalServiceContentProps = {
  setPostalCode: Dispatch<SetStateAction<string | null>>
  setCity: Dispatch<SetStateAction<string | null>>
  changeStep: (val: string) => void
}

type Option = {
  city: string
  code: string
}

const ProposalServiceContentPostal = ({
  setPostalCode,
  setCity,
  changeStep,
}: ProposalServiceContentProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      postalCode:
        localStorage.getItem(LocalStorageKeys.postalCode) || undefined,
      city: localStorage.getItem(LocalStorageKeys.city) || undefined,
    },
  })

  const [options, setOptions] = useState<Option[]>([])

  const handleChange = async ({
    inputValue,
  }: UseComboboxStateChange<Option>) => {
    if (!inputValue) {
      setOptions([])

      return
    }

    const res = await axios.get(
      `https://geo.api.gouv.fr/communes?codePostal=${inputValue}`
    )

    if (res.data) {
      setOptions(
        res.data.map((el: any) => ({
          code: inputValue,
          city: el.nom,
        }))
      )
    }
  }

  const {
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox<Option>({
    items: options,
    onInputValueChange: handleChange,
    itemToString: (item) => (item ? item.code + ' - ' + item.city : ''),
    onSelectedItemChange: ({ selectedItem }) => {
      selectedItem?.code && setValue('postalCode', selectedItem?.code)
      selectedItem?.city && setValue('city', selectedItem?.city)
    },
  })

  const onSubmit = handleSubmit((data) => {
    if (!selectedItem) {
      setError('city', {
        message: cityErrorMessage,
      })

      return
    }
    localStorage.setItem(LocalStorageKeys.postalCode, data.postalCode!)
    localStorage.setItem(LocalStorageKeys.city, data.city)
    setPostalCode(data.postalCode!)
    setCity(data.city!)
    changeStep('price')
  })

  return (
    <>
      <InnerContent>
        <Title level={2}>
          Entrez votre <span>code postal</span>
        </Title>

        <Form onSubmit={onSubmit}>
          <FormGroup error={errors.postalCode?.message || errors.city?.message}>
            <Select {...getComboboxProps()}>
              <Label>Code postal</Label>{' '}
              <Input
                {...register('postalCode')}
                placeholder="Votre code postal"
                error={!!errors.postalCode}
                {...getInputProps()}
              />
              <div {...getMenuProps()}>
                {options.length > 0 && (
                  <StyledOptions>
                    {options.map((item, index) => (
                      <StyledOption
                        isHighlighted={highlightedIndex === index}
                        key={`${item.code}${index}`}
                        {...getItemProps({ item, index })}
                      >
                        {item.city}
                      </StyledOption>
                    ))}
                  </StyledOptions>
                )}
              </div>
            </Select>
          </FormGroup>
          <Description>
            Votre code postal nous permet de savoir si vous êtes éligibles à une
            prestation sur place ainsi que le tarif à appliquer.
          </Description>
          <SubmitButton
            type="submit"
            icon={<ArrowIcon />}
            disabled={!selectedItem}
          >
            Accéder à mon estimation
          </SubmitButton>
        </Form>
      </InnerContent>
    </>
  )
}

export default ProposalServiceContentPostal
