import { airtable } from '../airtable'
import { Decision } from '../sdk/Decision'
import { getAnswerByKey } from './getAnswerByKey'

export async function getAnswer(key: string[]) {
  const decision = await airtable<Decision>('Arbre décisionnel')
    .select({
      view: 'Grid view',
      filterByFormula: `{key} = "${key}"`,
    })
    .all()

  if (decision?.[0]) {
    return getAnswerByKey(decision[0].get('answer'))
  }
}
