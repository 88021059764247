import { ReactNode } from 'react'
import styled from 'styled-components/macro'

enum Weight {
  medium = '500',
  bold = '900',
}

export type StrongProps = {
  weight: keyof typeof Weight
  children?: ReactNode
}

const StyledStrong = styled.div<StrongProps>`
  font-weight: ${({ weight }) => Weight[weight]};
`

const Strong = ({ weight, children, ...rest }: StrongProps) => {
  return (
    <StyledStrong weight={weight} {...rest}>
      {children}
    </StyledStrong>
  )
}

export default Strong
