import styled from 'styled-components/macro'

import { ReactComponent as CheckIcon } from '../../assets/icons/teleconsulting/check.svg'
import { ReactComponent as MoneyIcon } from '../../assets/icons/teleconsulting/money.svg'
import { ReactComponent as TimerIcon } from '../../assets/icons/teleconsulting/timer.svg'
import { useWindowSize } from '../../hooks/useWindowSize'
import { breakpointsValues } from '../../theme/breakpoints'
import Carousel from '../molecules/carousel/Carousel'
import BenefitCard from './BenefitCard'

const SmallTitle = styled.p`
  font-size: 19px;
  font-weight: 500;
`

const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 872px;
  margin: 0 auto;
  gap: ${({ theme }) => theme.spacings[6]};
`

const BenefitsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary[500]};
  color: ${({ theme }) => theme.colors.white[500]};
  padding: ${({ theme }) => `${theme.spacings[18]} ${theme.spacings[6]}`};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    padding: ${({ theme }) => `${theme.spacings[36]} ${theme.spacings[6]}`};
  }
`

const SliderTitle = styled(SmallTitle)`
  margin-bottom: ${({ theme }) => theme.spacings[12]};

  @media (min-width: ${({ theme }) => theme.breakpoints['tablet']}) {
    margin-bottom: ${({ theme }) => theme.spacings[18]};
  }
`

const BenefitCard1 = (
  <BenefitCard icon={<TimerIcon />} title="Rapide">
    Pour répondre à l’urgence, un expert vous contacte en moins d’une heure
  </BenefitCard>
)

const BenefitCard2 = (teleconsultationPrice: string) => (
  <BenefitCard icon={<MoneyIcon />} title="Économique">
    Bénéficier de conseils et avis d’un technicien pour seulement{' '}
    {teleconsultationPrice}
  </BenefitCard>
)

const BenefitCard3 = (
  <BenefitCard icon={<CheckIcon />} title="Efficace">
    Peut importe le problème, nous trouverons une solution pour vous
  </BenefitCard>
)

const BenefitCard4 = (
  <BenefitCard icon={<MoneyIcon />} title="Remboursé">
    Si l’intervention sur place d’un technicien reste nécessaire, le
    télédiagnostic est gratuit
  </BenefitCard>
)

type ModalTeleconsultationBenefitsProps = {
  teleconsultationPrice: string
}

const ModalTeleconsultationBenefits = ({
  teleconsultationPrice,
}: ModalTeleconsultationBenefitsProps) => {
  const size = useWindowSize()

  return (
    <BenefitsWrapper>
      <SliderTitle>Les avantages</SliderTitle>
      {size.width && size.width > breakpointsValues['tablet'] ? (
        <CardWrapper>
          {BenefitCard1}
          {BenefitCard2(teleconsultationPrice)}
          {BenefitCard3}
          {BenefitCard4}
        </CardWrapper>
      ) : (
        <Carousel slidesPerView={1} variant="white">
          <Carousel.Item>{BenefitCard1}</Carousel.Item>
          <Carousel.Item>{BenefitCard2(teleconsultationPrice)}</Carousel.Item>
          <Carousel.Item>{BenefitCard3}</Carousel.Item>
          <Carousel.Item>{BenefitCard4}</Carousel.Item>
        </Carousel>
      )}
    </BenefitsWrapper>
  )
}

export default ModalTeleconsultationBenefits
